const LatestMusicVideosStatic = () => {
    return (
      <>
        <div class="music-video">

        <div class="music-video">
                <div class="music-video-container">
                    <iframe src="https://www.youtube.com/embed/k-7oPEbGaC8" title="test" allowfullscreen></iframe>
                </div>
                <p><strong>Lil Dujour</strong><br/>KEANO</p>  
            </div>

            <div class="music-video">
                <div class="music-video-container">
                    <iframe src="https://www.youtube.com/embed/Bs84gP1d888" title="test" allowfullscreen></iframe>
                </div>
                <p><strong>ANJU Online!</strong><br/>Katy</p>  
            </div>

        </div>
      </>
    );
  };
  export default LatestMusicVideosStatic;
const LugosiFeaturesStatic = () => {
    return (
      <>
        <div class="gallery_containerThree-static">  

        <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/2y7Ob9mEbDcSN2zlr8gx4c?si=ec2010d33dac44a5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02794a65ba4b225e2f483fd3a1" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>zx sallow & Nate Hollow - Sacrifice (feat. Lugosi)</strong><br/>
                (prod. Gmanz)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/3V50ZTDDET86sQo9gKwFfF?si=cf0bd3d342094f3c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02b4005588bc8f2d1de3f3eb4d" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>notamachine - enjoy yourself</strong><br/>
                (feat. Kawaii Ry & Lugosi)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/6f0wOhCOhyz8S7pR5HiZGj?si=b6f34406a5b741d2" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02b4005588bc8f2d1de3f3eb4d" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>notamachine - faded</strong><br/>
                (feat. Lugosi)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/5KD8KacsVrnRgrIsmNhQTt?si=9528e5ffa2304123" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - lugosi in a ghost (feat. Lugosi)</strong><br/>
                (prod. Fedia)
              </p>
            </a>
          </div> 

          <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/1I44gwUuJyRXxuP1vp6JFQ?si=ec6174192bd341f9" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273194a8d46291067ad062d1077" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Loudpak Shawty - Don't Matter (feat. Lugosi)</strong><br/>
                (prod. notamachine)
              </p>
            </a>
          </div>   

          <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://soundcloud.com/kawaiiry/no-smoke-ab-minor?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - NO SMOKE (feat. Lugosi)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        </div>
      </>
    );
  };
  export default LugosiFeaturesStatic;
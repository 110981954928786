 const KawaiiFeaturesStatic = () => {
  return (
    <>
      <div class="gallery_containerThree-static">  

      <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/62QksB8fuA5h5SriHVm3sh?si=23994054e9fc4b48" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02992faa6abe177a9941899ce9" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>g00fy bitch free$tyle</strong><br/>
                (prod. Kawaii Ry)
              </p>
            </a>
          </div> 

      <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://soundcloud.com/dujourmusic/kawaii-ry-dujour-this-my-last-one-but-i-can-break-it-down-in-half" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-CX8LoV5NILXcG9lS-aEpOTw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Lil Dujour & Kawaii Ry - This My Last One But I Can Break It Down In Half</strong><br/>
                (prod. Mista)
              </p>
            </a>
          </div>

      <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://soundcloud.com/dujourmusic/kawaii-ry-lil-dujour-conceited" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-z7hfX094yoqjG26P-FJhglg-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Lil Dujour & Kawaii Ry - Conceited</strong><br/>
                (prod. Lil Dujour)
              </p>
            </a>
          </div>

      <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/1E5hPGQOOUvjnqwkML1Gws?si=8cc395eb94bc4a21" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02794a65ba4b225e2f483fd3a1" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>zx sallow & Nate Hollow - United Nations (feat. Kawaii Ry)</strong><br/>
                (prod. Gmanz)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/3DAMzEoeQukDNrsa0qZXOV?si=9c129d8af7554866" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02794a65ba4b225e2f483fd3a1" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>zx sallow & Nate Hollow - Carrying K’s (feat. Kawaii Ry)</strong><br/>
                (prod. Aureola)
              </p>
            </a>
          </div> 

          <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://soundcloud.com/dujourmusic/if-its-me-or-u-its-me-every-time-ft-kawaii-ryprod1awesome" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-kdY8Ehe36GlVyqS1-t8JCAA-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Lil Dujour - If Its Me Or U Its Me Every Time (feat. Kawaii Ry)</strong><br/>
                (prod. 1awesome)
              </p>
            </a>
          </div>

      <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/0EOFxaLHwDn26glk2cWKCz?si=b055df2171804937" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02e35e67fb293f1b2356e43371" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>kn1febaby - Forreal (feat. Kawaii Ry)</strong><br/>
                (prod. Ruci)
              </p>
            </a>
          </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/0YLCTucom54tdgSZ4dxL6y?si=530a22c58e924185" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d00001e0226aa69fc6987ad9b20b68d93" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>TRYFG - 0__00 куплю рюкзак хелло китти!! 0__00 (feat. Kawaii Ry)</strong><br/>
              (prod. TRYFG)
            </p>
          </a>
        </div>

        <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/0AfCU5Ta1U7n3D5MStX5c3?si=7c729855a1c14487" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-bKxQu7uZqJ4w1EDK-U86hsw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>zx sallow - steez of the year (feat. Kawaii Ry & Lexik)</strong><br/>
                (prod. shade08)
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/5fWXYtkqK5gEJBrIe3XXiA?si=567c0787fd264bf1" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02f1102f1e0a4990f9494b5633" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>8ngelonline - Ecstasy (feat. Kawaii Ry & woes)</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/4AsnKcz8tPUixESzOSQwrC?si=c0913eaa711d4d47" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e025c5d7234a74ca5a0a9ceef9d" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>chris6lair - Strawberry (feat. Kawaii Ry & scumboi)</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Feature</p>
            <a href="https://open.spotify.com/track/5iw3aYvepVEbVJpyuWrKOc?si=6d58febd77b3411a" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e0265c4452b75195eb8a08646dd" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>zx sallow - realest steezer (feat. Kawaii Ry)</strong><br/>
                (prod. Geist)
              </p>
            </a>
          </div>  

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/25UlkXqFHvwxPeGZhokNa8?si=a0ac72e67a304531" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d00001e02b4005588bc8f2d1de3f3eb4d" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>notamachine - birdsongs</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/3V50ZTDDET86sQo9gKwFfF?si=cf0bd3d342094f3c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d00001e02b4005588bc8f2d1de3f3eb4d" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>notamachine - enjoy yourself</strong><br/>
              (feat. Kawaii Ry & Lugosi)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/67DUyxGfvco2I9WmR0u6kO?si=ae55ddc10bdf40f2" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d00001e023595397e5851ee6203358080" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>STICK GOD - RUNNIN’</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/1oSqlaTf5EDR5bFYkhDZGq?si=a5d1b68172b04baf" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-vWB1gQFkHcjOFhJO-UxYAYg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>TRYFG - слэп!! (feat. Kawaii Ry)</strong><br/>
              (prod. TRYFG)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/5R6kaUncgpb6XuulgTUSTg?si=f6ff03033cbe4727" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-vWB1gQFkHcjOFhJO-UxYAYg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>TRYFG - hannah montana (feat. Kawaii Ry)</strong><br/>
              (prod. TRYFG)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/5l50h1VPMMg5Kv7tc95Xio?si=3d4bf9b6961d467d" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-vWB1gQFkHcjOFhJO-UxYAYg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>TRYFG - marilyn monroe! (feat. Kawaii Ry)</strong><br/>
              (prod. TRYFG)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://soundcloud.com/prodgotti/further-with-kawaii-ryy" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-dOKOMvavpxFw4ct2-NEo6Ng-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Gotti - FURTHER (feat. Kawaii Ry)</strong><br/>
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/6f0wOhCOhyz8S7pR5HiZGj?si=b6f34406a5b741d2" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273baf9695f125261f9cecc3f90" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>notamachine - dont4getme</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/album/6GPMxxzNf8zxoQ0gKY8vU5?si=UA-0UYy7TwG8sHLCrY4JsQ" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d00001e023c8dea1412143c1eebd29db9" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>ReTread - BlueLabel (feat. Kawaii Ry)</strong><br/>
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://soundcloud.com/tryfg/cloudflare" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-R6uejmF0UVvyuNAj-FFVMww-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>TRYFG - Cloudflare (feat. Kawaii Ry)</strong><br/>
              (prod. TRYFG)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/7APoEgwv7Vs79Hk23Q0zRN?si=5055094ec850402c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273d7874a8d7e99af600a1a37bb" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Semaj - Angel Face (feat. Kawaii Ry)</strong><br/>
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/0Ro11kMgsHlHmWm8KyKfyI?si=e0e20d3b87ee490f" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-wZfZQLtYA2RTwxXE-gSGKLQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>TRYFG - soda grape! (feat. Kawaii Ry)</strong><br/>
              (prod. TRYFG)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://soundcloud.com/kawaiiry/too-easy-kawaii-ry-remix" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-YhgyXPp3BaUApWs1-OyyTUQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Gunna, Future, Kawaii Ry - Too Easy Remix</strong><br/>
              (prod. Wheezy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://soundcloud.com/kawaiiry/lil-keed-gunna-kawaii-ry-curtains" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-7UUyiSqaMnKPdyTY-IwpjBA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Lil Keed, Gunna, Kawaii Ry - Curtains Remix</strong><br/>
              (prod. YoungBoy Brown)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://soundcloud.com/user-495687265/remy3d-dont-talk-about-we-feat-kawaiiry" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-KCftv46GjY0ybXmV-ys8mXg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Remy3D - Don't Talk About We (feat. Kawaii Ry)</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/4Vc5BPUjASZnKeeuYoXJbb?si=365515680494448a" target="_blank" rel="noopener noreferrer">    
            <img src="https://is3-ssl.mzstatic.com/image/thumb/Music114/v4/d5/a2/44/d5a244fd-1db8-6ea1-6300-458309d7fb4e/5059324589833.jpg/1200x1200bf-60.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>panda slugger - Plug Robbery</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/43APfqWaiXNXltct5t4Lkc?si=be2e5eacadb74856" target="_blank" rel="noopener noreferrer">    
            <img src="https://is3-ssl.mzstatic.com/image/thumb/Music114/v4/d5/a2/44/d5a244fd-1db8-6ea1-6300-458309d7fb4e/5059324589833.jpg/1200x1200bf-60.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>panda slugger - Whole World Froze</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/5nq9WlrCSxT5ohzhyHMts4?si=65aff10b15ba4a9e" target="_blank" rel="noopener noreferrer">    
            <img src="https://is3-ssl.mzstatic.com/image/thumb/Music114/v4/d5/a2/44/d5a244fd-1db8-6ea1-6300-458309d7fb4e/5059324589833.jpg/1200x1200bf-60.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>panda slugger - What We Used to Have</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/6STl7gABycwdZmWM3T9wBG?si=c3f9b8fab27945d9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d00001e028439eb45880945bfacf42bf6" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>prettyboigordo - BLAST 4 ME</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://soundcloud.com/kawaiiry/yung6ix-on-a-daily-20-feat-kawaii-ry-onadailychallenge" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-42Isfy25gEs5S4Sb-D41jow-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Yung6ix, Kawaii Ry - On a Daily 2.0 Remix</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/7DqKnfsHGSqEOTF4pK36eN?si=64314b833d4c4de8" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b2739572d52c3c656068dce4366c" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Lugosi - THANOS (feat. Kawaii Ry)</strong><br/>
              (prod. Sorrow Bringer)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/7lS1xUQx6sHEudgrOLfZu3?si=0124317b5b24496b" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27325e9ba744fa8abf7d19b0ba5" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Remy3D - Duffy (feat. Kawaii Ry)</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/3PLO9EIeEjTX6F2iI2F6EH?si=5247c064d3f540b6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273647ee7b32c9d2e7f160ff352" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Lugosi - Wanting More</strong><br/>
              (feat. Kawaii Ry)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Feature</p>
          <a href="https://open.spotify.com/track/6POWh3UdMlcAqNU5lHTgvm?si=296d88b3c2ac4a38" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b6df80eb65bc106fbea8042a" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Remy3D - Without You (feat. Kawaii Ry)</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

      </div>
    </>
  );
};
export default KawaiiFeaturesStatic;
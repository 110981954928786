const SemajSinglesStatic = () => {
    return (
      <>
        <div class="gallery_containerThree-static">  

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7vcras92OZSYG6hTUzASsJ?si=6a1a12dc164041e8" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02a814abaa22deeaf7200af252" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>What I Do</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/52WWjgVKggWavnfkSBrNvw?si=a1754dbff028470a" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0270e6c467c9b0f9ffa09d287a" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Pick Up the Phone</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/album/6XVPk0HVvUxidKE7tRtXYr?si=VuEhdfNaTgmU2mo5Nxmqww" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0210eaa4d73c13f966300eb088" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Stick By My Side</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3mD4FwrHVPf2ZQwvaKree4?si=2bd5aca8f10f4ec6" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e025e2a34dc4644b169f30f6b44" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Love's Not Fair</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7dy9xmY6Ei20AjyEdhXSg9?si=3c9d7dccba1342cb" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0267311d28774ca875d4e5e4b7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Keep Quiet</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2OQUzGnNi4BYDPYhLLkfzi?si=6d0717d55ab34948" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27365972bcc710045581cd0a935" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Heartless</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1Zu1h83Iblsg8HbbCjivaQ?si=19d6439a9f6948e8" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0282d3274a098b9be639dc6125" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>All For Nothing</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5lOAp8UYEVwfOA4J3ZXtRj?si=50cc379840a84648" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273592825391a9e3e954a11f917" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Smile</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/05NcdpGP7jAPJ5is5KTPIP?si=2ddc5370f9de435d" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27376c812a9d0253fd87b5cfd7e" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Worth It</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1NNCG385bXJIG186MEYa9e?si=f1075dea671d4083" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273967c7ad7b3c78178c8db69ef" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Only For Tonight</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7APoEgwv7Vs79Hk23Q0zRN?si=5055094ec850402c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273d7874a8d7e99af600a1a37bb" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Angel Face (feat. Kawaii Ry)</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1DBRSSN11o2yqhZceKKwfI?si=2e4a3b32928645fe" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27308c9210a894d9bb2edbd078d" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>She Wanna</strong><br/>
              </p>
            </a>
          </div> 

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1roUytawfU7bvmRAp0jKS7?si=a46a93751c2e4259" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b2732eaaa7a9eb612f82545f3f84" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Push Start</strong><br/>
              </p>
            </a>
          </div> 

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6QfRQ80sgGQeMTITICrzvO?si=d7d4e8e24540406d" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273f09f33e06c7564b1c2e6dd8e" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Rain Dance</strong><br/>
              </p>
            </a>
          </div> 

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0tI00xSff5T02YWjQEqOm2?si=4fee6460d7ae407b" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27320cd8753316bc893b73ea97a" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Frozen</strong><br/>
              </p>
            </a>
          </div> 

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/75I9ib0Q7QGK4V0dcCQ5AX?si=e3a084baef864f65" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273f0be79aecea6428e0abeeb36" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Some Nights</strong><br/>
              </p>
            </a>
          </div>     

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6S3lQhuPlXPs2q0eDfQJOy?si=d675021e40204edb" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b2738f7e3bbd13bfcaae0debfa5d" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Slow Motion</strong><br/>
              </p>
            </a>
          </div>          

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7str1wN953YOw143b1YuEJ?si=1c007be965f245b1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27341668d3ed04cfab6723b7590" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Tell Me</strong><br/>
              </p>
            </a>
          </div>

        </div>
      </>
    );
  };
  export default SemajSinglesStatic;
const ArchiveMusicVideos2019Static = () => {
    return (
      <>
        <div class="music-video">

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/BtDMZBQVC5Y" title="lost freestyle" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>Lil Dujour</strong><br/>
            Shawty
          </p> 

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/I4mOPjK6e1o" title="lost freestyle" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>Lil Dujour</strong><br/>
            PRAY 4 LUI (prod. Lil Dujour)
          </p>   

        </div>
      </>
    );
  };
  export default ArchiveMusicVideos2019Static;
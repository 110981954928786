const SemajAllReleasesStatic = () => {
    return (
      <>
        <div class="gallery_containerThree-static">  

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7vcras92OZSYG6hTUzASsJ?si=6a1a12dc164041e8" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02a814abaa22deeaf7200af252" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>What I Do</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/52WWjgVKggWavnfkSBrNvw?si=a1754dbff028470a" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0270e6c467c9b0f9ffa09d287a" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Pick Up the Phone</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/album/6XVPk0HVvUxidKE7tRtXYr?si=VuEhdfNaTgmU2mo5Nxmqww" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0210eaa4d73c13f966300eb088" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Stick By My Side</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3mD4FwrHVPf2ZQwvaKree4?si=2bd5aca8f10f4ec6" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e025e2a34dc4644b169f30f6b44" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Love's Not Fair</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7dy9xmY6Ei20AjyEdhXSg9?si=3c9d7dccba1342cb" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0267311d28774ca875d4e5e4b7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Keep Quiet</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1h1bV5x6Rqak2ZYNbc6JzC?si=0b1c59bbfcd64ba8" target="_blank" rel="noopener noreferrer">
              <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry & Lugosi - FYBB</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2OQUzGnNi4BYDPYhLLkfzi?si=6d0717d55ab34948" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27365972bcc710045581cd0a935" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Heartless</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4mX5Cv2hxmb820ZIWhEeXj?si=cc1439f22d784f54" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02eb6ed43d5b7a864793e53697" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Craze</strong><br/>
                (prod. notamachine, Semaj & noahmejia)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4Hy8Gumbaxxu72udHRQI5D?si=e3cc3977b2224a3c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02eb6ed43d5b7a864793e53697" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Biggie Bag</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2sOXUprrPY5YcMeI6pGFeQ?si=826ad058f4ec4d41" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02eb6ed43d5b7a864793e53697" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - CK Lessons</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1MdhDRH1HByEP2Ggq45K8H?si=d46cab217bba4caa" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02eb6ed43d5b7a864793e53697" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - I Hope They Remember Me</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1Zu1h83Iblsg8HbbCjivaQ?si=19d6439a9f6948e8" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e0282d3274a098b9be639dc6125" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>All For Nothing</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5lOAp8UYEVwfOA4J3ZXtRj?si=50cc379840a84648" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273592825391a9e3e954a11f917" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Smile</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/05NcdpGP7jAPJ5is5KTPIP?si=2ddc5370f9de435d" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27376c812a9d0253fd87b5cfd7e" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Worth It</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/fiend-prod-semaj" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-vvfmSRs9Jph3PnDo-mlFLyA-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Fiend</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/queue-timer-prod-semaj" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-lOBaQWngDe7OMz4a-tx7GKw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Queue Timer</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1NNCG385bXJIG186MEYa9e?si=f1075dea671d4083" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273967c7ad7b3c78178c8db69ef" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Only For Tonight</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7APoEgwv7Vs79Hk23Q0zRN?si=5055094ec850402c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273d7874a8d7e99af600a1a37bb" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Angel Face (feat. Kawaii Ry)</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1DBRSSN11o2yqhZceKKwfI?si=2e4a3b32928645fe" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27308c9210a894d9bb2edbd078d" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>She Wanna</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/cc-crazy-prod-semaj" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-WT8UfTTW3ce7WZPk-MF1bmw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - CC Crazy</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1roUytawfU7bvmRAp0jKS7?si=a46a93751c2e4259" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b2732eaaa7a9eb612f82545f3f84" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Push Start</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1fnXKLL9KimuhaWXGaWJ7L?si=cf32102e4e1c4ff7" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Pocket Rocket</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0u7CkqJAJfU5GWJghcGo0F?si=828908c38d0249c9" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Faceless</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0u7CkqJAJfU5GWJghcGo0F?si=828908c38d0249c9" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Depression & Weapons</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/5ZzifrnAgHhaGaV9errGJE?si=2f0c82c0e2574cd7" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - For Sure</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/50j7SvFmVEOL4UT19VlryO?si=6bedf12e750d4621" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Makes Me Sick</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6QfRQ80sgGQeMTITICrzvO?si=d7d4e8e24540406d" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273f09f33e06c7564b1c2e6dd8e" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Rain Dance</strong><br/>
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2TgcJquVo5kNa8uG2YcCnt?si=41ba12389e5d4d88" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Touché</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1VIXONQbUwV1WNdHexT0r2?si=9358f708604748be" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Restart</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/261ls3bLUNQNdPiHEBrv2p?si=ba52d54e54bf4c75" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Late Night</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4UpWvmngyhuFMNAfoCr8W1?si=de6dd99101924baa" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Romeo & Juliet</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1zBUX5VK4Vot6MWjTyA6i1?si=314e0d5e1c8c402c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Love Kills (Time Heals)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4winwPkQZ17ZuLilT1u4ya?si=22a4a3d8264b4977" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Keep Me Safe</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/22P9XDIPK45Lsi738qJ6NH?si=22ba3de16ec64d44" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Bloodloss</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/045Lyssqnyxo38RPI1vtyS?si=41f98c0e914447c4" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Right Time</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2rE604PA28rXOqjK3IIJur?si=129349363f694dd6" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Laid Back</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/78tOzpKgBiXvRxtP3XsrGr?si=276e642c9e724ce5" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Crystal Ball</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/5OZKTrLFiYeCUsbKdP9p5u?si=a8e4cf1235d9475d" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Closure</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/6w5cRaDfeyuw2WXwyB7Hs9?si=69482585257d4a7f" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Thoughts at 4AM III</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4W1kZZP01KOgfabsGBi83C?si=5727f9d7b8594a0c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Under My Covers</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0yHBJltb87n0PRjMZ98ug2?si=c778bf2178984136" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Misunderstood</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/3cMwuaNDdUgPK2bwhiqIAJ?si=ecebd74820704144" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Funny Thing</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4PuSCGTBmdwIy85EAxZeMx?si=cc41c243c02c4572" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Heaven Sent // Bringing Hell</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2uhd0YNuMImu42dhEuB4DD?si=4758e75dc2984c6a" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Forbidden Love</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2MOLWYHb0S0eQOLsk1Cv9a?si=7363a8bd962c4fb6" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Love Robbery</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0tI00xSff5T02YWjQEqOm2?si=4fee6460d7ae407b" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27320cd8753316bc893b73ea97a" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Frozen</strong><br/>
              </p>
            </a>
          </div> 

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/75I9ib0Q7QGK4V0dcCQ5AX?si=e3a084baef864f65" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273f0be79aecea6428e0abeeb36" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Some Nights</strong><br/>
              </p>
            </a>
          </div>   

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/09xp0Y5oVvBNkEYqo5QJW8?si=76d82a5d113c456d" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - i was scared of love... would i ever go back?</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/7nKoIh4oRcxJjwubpUZGkX?si=8bc750f0bbc9471e" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - i was made to be broken</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/60OlQ6H7j6SiTMxATK9jzj?si=5ecdba71a1f64989" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - dont play</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/3zVtZOp9VuZMznJmFi9aAn?si=97219e30e0b74429" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - sixteen oh four</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/3JgFOUJ9Wf9hgYKuiTx3O7?si=282f46548bf64538" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - heart cold (feat. Remy3D)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0kiZLbEx9UqDwpAchishqn?si=9ce34465862b4aea" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - deadman wonderland (feat. mathieu!)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/7tHt2vB1GePmUOBsX932DP?si=84a8091dfdf84082" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - lately i just hate/love us (feat. Lovesickbray)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Feature / Placement</p>
            <a href="https://open.spotify.com/track/55pVAmXrwyFiBr9tKSCHVQ?si=8e237705f25c48bc" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - fall into the crossfade (feat. Semaj)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/64J9zNPV5quWjyKK6UZSN7?si=d7c359f3e44442b7" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - lately you aint calling me baby (feat. Uglyboy)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/bottomless-prod-semaj" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-DhryXJnqHyStjcoY-Ujivig-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Bottomless</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/6BiCsa0VSNWsylxyvxKI4P?si=d5293ba9c0a644fc" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Switch Lanes</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/43dwCnV2oKixkx44FfeLns?si=a9010189fdd2477b" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Waterfall</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/lootd-up-prod-semaj" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-BiATc3nFWxJ9wGTD-j0kNaA-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Looted Up</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div> 

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/jammer-boy-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - JAMMER BOY</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/funds-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - 333 COLOGNE</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/no-smoke-ab-minor?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - NO SMOKE (feat. Lugosi)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>        

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/idk?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - IDK</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/youll-see-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - YOU'LL SEE</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/loving-you-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - LOVING YOU</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/calling-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - CALLING</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/dont-love-me-for-clout-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - DON'T LOVE ME FOR CLOUT</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/walk-in-closet-prod-semaj" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - WALK IN CLOSET</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/lillugosi/disposable-prod-semaj" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-JiSnVvbUUrB7ZMh5-zYJFhA-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Lugosi - disposable</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>  

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6S3lQhuPlXPs2q0eDfQJOy?si=d675021e40204edb" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b2738f7e3bbd13bfcaae0debfa5d" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Slow Motion</strong><br/>
              </p>
            </a>
          </div>          

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4S2RqsFNrCXWkd51LTQ51u?si=d8f4b221ee14476f" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Pretendo (Disscord)</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0U4G95Mzz8GEiv0ppHDSJ6?si=93c8e4f77dca4897" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Schwacked</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1HWUkxyowLO4Mp7pKY9qwa?si=18ad028153134d18" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - F-Type</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4g5b1CGjeAZuPQAbkfi49v?si=e7096044fe2e4016" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Rainbow Road</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2c5eLBJrxHHfQTlhOzPgXB?si=8652ea4fb03d4acf" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Toad Arc</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/08hPcgX8YjZNHO6i5MfoZ7?si=2464318383ea44f5" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - The End</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/75GhpmjN68kAJF0NBQtPyt?si=6aa456e057974a32" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Levitate</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/52UoxhW2ykTbPIhiZ0OS26?si=3978dda2c3fa4122" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - More Than Hate</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/06JqQxCOz5IKZLLqgiIJDD?si=67d5d29a42f74ace" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Wrecking Ball</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/5FigJ1pp2FlhPqjZu1xPqP?si=9f90acc8e4d94aae" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Stormy</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/6X7EID0maKLLRh8HzFTzp7?si=d05aa6f5ce8b42a1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>I Don't Want This To End</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/3S93HLTSA5r5aF8sRZzG5q?si=5061c18cb0184192" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Think About Me For a Second</strong><br/>
                (prod. Semaj, notamachine)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/03FCS2Mj1IlBviCaUzbsj8?si=d71cfe6b504840e8" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Exit Life</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/30ikBKFahf1a8x82ROCK2D?si=1a56a5663e084d8d" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Can't Keep Up</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/3S5CUlVp3jEVk7kJpvP3N3?si=504d8e3e6e1b4c6c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Suite Life</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1incJfp7SNou3twKGiJgua?si=5b3c602f1c544be2" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Open Door</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/5gGfAeJhWS8DawnFR7oin7?si=7d74df34f0784756" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - I Just Want Forever</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/732MJs62ktGT7yoza0SWVW?si=84faf233856b4cf0" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Nice Fit</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/5rb5WA27YIIBORmqwo3V4U?si=8abe7d2200b24a07" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Take Your Time</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1VHeipx0QDCCsZrsybfL13?si=8d81b28e4c304063" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Pretty Girls</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/5f8n3biiRHtKiH53bwS4mS?si=f651aeab02f34761" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Love Scars and Broken Hearts</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/055Vyu8DSSKy6UTtmt1bbK?si=ed514e8d2e1d46bc" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Out Of the City</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/25vjTqppKMuoy0BzjE1Qd8?si=cd91a62222bc462c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Paint the Picture</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0SF05Z7W9Uegz34QBF4kyo?si=649ce3197c994760" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Nothing Like Your Old Girl</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0kbuD96UyLQUgJ1jkYFVi9?si=29f51b45b2be4011" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Days Off</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/11quiOkuOVgkoy7DTjmzVx?si=91d39c2a9cd34eef" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Off The Leash</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1gc6YW1UHVhSVcNJbcrdXI?si=2db5e6e501994bf7" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Twin Twin</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0cZjX9dvc26WO1XvznWexm?si=a7e361b2782f430c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Mystery</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7str1wN953YOw143b1YuEJ?si=1c007be965f245b1" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27341668d3ed04cfab6723b7590" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Tell Me</strong><br/>
              </p>
            </a>
          </div>

        </div>
      </>
    );
  };
  export default SemajAllReleasesStatic;
const KawaiiSinglesStatic = () => {
  return (
    <>
      <div class="gallery_containerThree-static">  

      <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://soundcloud.com/kawaiiry/habit-remix-m" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-HbQQX1LsfyLLH2L7-xev1gQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Lil Durk - Habit</strong><br/>
                (Kawaii Ry Remix)
              </p>
            </a>
          </div> 

      <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/38mJNU36DX5bruypiJoOws?si=08909b28b1644028" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Shock Val</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5hi9DNUbDncI4NX4WAXPzp?si=2a695b7422914eb2" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Draw The Lines</strong><br/>
                (prod. chris6lair & macrazy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5d9AqdT01TCFzbMXaFiJnK?si=73ba5692b70845d5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Red Ball</strong><br/>
                (prod. chris6lair & macrazy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1TeP49hzq0w4gvDOCG4cZq?si=87f5b72a34f64658" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Round 4 Round</strong><br/>
                (prod. chris6lair & bkthebagman)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/25S4QRyhyCTV0RGj9nyTeI?si=7dee982eb91f42f4" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Make Sure</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7l55POICXwbXlpX0hweElv?si=b814e014fed9476f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Perfect Product</strong><br/>
                (prod. chris6lair & tf)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6UdvVFt8FlWvrN3DIQFDTT?si=8fcbf2946f8443c5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>They Not</strong><br/>
                (prod. chris6lair & tf)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/26vstrW3eSAs4VDOQBA51w?si=e5f981dac3f74bf8" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Can't Stop It</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1CRPcN5KMzt9bA27X7i4FW?si=390a30a2dd874267" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>WTSA</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1NSYLVmNh63vHuARY1rYNZ?si=73fd972f7dae43f3" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Destonia (2D)</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1HBnNHqUHTn4ljGuDEoYNE?si=b3eed10ebdb8491a" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>More Praise</strong><br/>
                (prod. chris6lair & tf)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1EH07KtTJ5PPCYKN68GAt8?si=16309e939b6345e1" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Always Go</strong><br/>
                (prod. chris6lair & tf)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0zcwQPBFAi4lTDDpANfDTF?si=0ae55744ce3340d4" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Aww Whatever</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/22FU2Fc193nNZjuwyENhGe?si=428d2b0deda74e95" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>TRY & Kawaii (feat. TRYFG)</strong><br/>
                (prod. chris6lair & bkthebagman)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6aKFBS0ZGUqVhrD0Yl9s5z?si=277a3561e0a64695" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Rocket Pop</strong><br/>
                (prod. chris6lair & tf)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/23V2T974kum0PPdRxldei9?si=b1ec949398fd45e2" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>See Me</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0hEA9BuAt5IGUspt1OaMnX?si=d12e0a88f0184852" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Yeah, You</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3djIYgKASRzFOunaRx3dp5?si=4e32816776f94c1d" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Ain't Nun' New</strong><br/>
                (prod. chris6lair & tf)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3czqZygo8Z3dH1owNh0HCv?si=fa9c93f295054721" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Talk On</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4LtZNm7jBsglPmwCPLpaz0?si=a0204b5cd4a2479d" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Photo Attachments</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/70hOg4Rs0Lwv0XDngOpHqk?si=206bbc71361c44ad" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Dearly</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1SZ0QWWosnbutwoVOZMDOp?si=c84b6242a7fc4055" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>DALYDK</strong><br/>
                (prod. chris6lair & notamachine)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5VtP65iYhLmdZimeUFZYvF?si=35c3d99b556b4bd9" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Favorite Girl</strong><br/>
                (prod. chris6lair & jibaorockin)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/315XTfKtvJKKlwY3UGi9tf?si=77cd52e1884c4061" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Protect My Heart</strong><br/>
                (prod. chris6lair & 36ix)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5fmhSgdxcnta8SsuokX5Ck?si=12c3742930ba425b" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>GG Baby</strong><br/>
                (prod. chris6lair & tf)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0l6mkErNP2LKWkIdwFAmDO?si=dfa7c4e61e124a03" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Fall Back</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4x9bEgP0cpFuumhqAkbBlP?si=ff6979401e8e406f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Hard 2 Forget</strong><br/>
                (prod. chris6lair & jibaorockin)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0d1P0tD8yST2RssOu3cUd1?si=b073458a40df49a6" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Said I Wouldn't Drink</strong><br/>
                (prod. chris6lair & jibaorockin)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5oxj9ERJVSkSQHhAzoQFOY?si=2c114860c07e4e5a" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Chronobreak</strong><br/>
                (prod. chris6lair & desertpunk)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2rJOrVyVi4Oc8MlvtE00Za?si=9a3b5e371eca4057" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Turn A New Page</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/67eTGQITXt2XsHWlDMb08b?si=1810c122328f4888" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i.scdn.co/image/ab67616d00001e02165adc96751fcdfd89a71fb8" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Disenchantment</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://soundcloud.com/kawaiiry/hold-the-key-prod-waves20" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-4xk8wtVybJSwmpru-EjfMyA-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Hold The Key</strong><br/>
                (prod. Waves2.0)
              </p>
            </a>
          </div>

      <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6osShCv2xrFOXXWfKi09dA?si=a1c38f1fb434436d" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Tension</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/434gdpFVdFshVz4ySa7t7I?si=d9702a3ee135464f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Shoe Box</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0LO933yt9DECV7FalIzvU9?si=c3bed6c32c89420b" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Step, Flex</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7huYDkWwsmtkZD0pW1UPmM?si=792873e779134e01" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Feelin' the Fire</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1rFkm5FD7wS3Ngo5iMRK5O?si=5157a68c11c14278" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Looker</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2zKlXvfoygBfPMvVkO5oZq?si=f33bd3cab92247ae" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>I Know the Feeling</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2aMnTjXPL8SiUiL7Hndzea?si=b0066ca05e624e95" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Jackpot</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4rCOrRJssWn9ZNtWfKS3EX?si=31db4940744349a7" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Too Rare</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/45zNPVMRj96XzKvvV6fkGE?si=3852e7220b174647" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Freethrow</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6nsSxnTjxtvFPHs4unRLbo?si=fa14c5cd8f274829" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Can't Leave Me Behind Yet</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3KoFBz2wYAPWcU6ZJOmZgp?si=786fb6be473f4d03" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Don't Ask</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6GwqVaExsrDGVkKzNvEG5K?si=1cc56be875044e23" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Disguise</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1DGXdsPq72menepUv3GApV?si=7351866c41794b88" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Forever Bright</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/462FnVoFOlXp5s55P5kYJC?si=416a1eec97ac46d9" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Man Down</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5WwAbV2fDV3l8Qe8uK9XNv?si=d46ff899248b4347" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Heart</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/66T6h82HCUB0VaCt0o3Dr5?si=0a3641b719164ad5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Drain Gang</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7hBKl3IRrbCopFa68oBjHZ?si=b497859d3520410e" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Space Quest</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3bpQ4AqdDlE9TkafnopTqJ?si=d451ac55c4f7493b" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>WWYD</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6zlj32XaYaObBuwoAXdeuK?si=dfc86ca59ca349b1" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Lightswitch</strong><br/>
                (prod. Baredex & Remy3D)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5eFamN5onvvL3bfq3V5sdR?si=49f315bc993c4740" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Don't Know Why</strong><br/>
                (prod. Baredex & Remy3D)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2tRsvJXEbbkkxnwXDrfqFl?si=b790976e1a044328" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mepLuhhx8vY3fKo0-ZFrxug-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>My Year</strong><br/>
                (prod. Baredex)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5lplVbznI9CX3juzRrECZo?si=bd1c0401ddbf49ec" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Weatherman</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/15D9l52nbbEti5NNfSzIQA?si=7007e4ecc5c0452b" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Tea Time w Bleezy</strong><br/>
                (prod. chris6lair & itsexo)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4mF71iNvcwqd52EDlJmWJA?si=00b8f0c352f24322" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>One In the Chamber</strong><br/>
                (prod. chris6lair & TF)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1goipLG3aHLX9CgfsjEq01?si=1500bd441bd84b53" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Bigger Bag</strong><br/>
                (prod. chris6lair & TF)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2s9aSLla01ZyVKxwQHTphw?si=bc29467a874d4b0d" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Not Televised</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0Nq9H1aHSkkqVPH7HnolWB?si=66851e5d9ac2427a" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Bloodhounds</strong><br/>
                (prod. chris6lair & TF)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6AHsKtxy4nOlkxI7NvK4on?si=360556aaec1543e4" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Damn, I Got a Lot</strong><br/>
                (prod. chris6lair & venexxi)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4M9nJnkDgPRkQEC0rTwovq?si=833884d12e97432a" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Diablo</strong><br/>
                (prod. chris6lair & TF)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6y7ySEeJYQBfTOTMZ3NeKC?si=6a57c1db11e64443" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Output</strong><br/>
                (prod. chris6lair, 1gokami & themed1c)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2Lp3ddYQXil4dHwFw42uE6?si=30f7a6ddf34847d6" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Burger Boy</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5RqXaw8BHxf1VGoI6ejaoO?si=81e9b3e7d1f74dc9" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Goin' Electric</strong><br/>
                (prod. chris6lair & morecalcium)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1l9JGtp9tH03QmrXZlD75U?si=f73e36ae035543dd" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Hellscape Z</strong><br/>
                (prod. chris6lair & bkthebagman)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/728MpE4KNMHWKQwybk390E?si=046cd2d38e784236" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Bad Habits</strong><br/>
                (prod. chris6lair & TF)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3zlA1Vbu50aUBcLIbdb7Dg?si=b22ee9c8e1d34a29" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Sum Fye</strong><br/>
                (prod. chris6lair & TF)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1vISkBVnija2CpaFuFY8aK?si=da32ee9fe0354d53" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Bleezy Mindset</strong><br/>
                (prod. chris6lair, TF & bad.technique)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5HisbDFgluLgxP1qecteF1?si=e2c2635aa64e47b3" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Deer Lease</strong><br/>
                (prod. chris6lair & imastarborn)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5TK7qZFX5GCEkqnsIdbhxh?si=01b6fa45b25f497f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Cave Diving</strong><br/>
                (prod. chris6lair & Rio Leyva)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/34TzEjf3UuDfz7jkAQmm7B?si=5b7d6ab422974b74" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>GLOW</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6DSM7FGlzBAmKPXl3f7ly6?si=65815f5c31204ae6" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Hero</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4KfWnKvLeRLYaBqxJwB3aZ?si=e1d823edb30a4653" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>KiKi in Paris</strong><br/>
                (prod. chris6lair, starborn & m0riss)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7vPW9Aa4iTf6lVkdOXvJbu?si=f3b30b9b2eef4570" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Q</strong><br/>
                (prod. chris6lair & bkthebagman)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6oktMWJA3ERcFRWrmVMi46?si=0bd230c26a4445e5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Packie</strong><br/>
                (prod. chris6lair & toobears)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2go3ogWaeQyHl6g2e6nnuH?si=832721a1e37b41bb" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Got Mail</strong><br/>
                (prod. chris6lair & toobears)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1GPCOExXsaaYjJJqOuMefO?si=f38d0fd307da4788" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Truant</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5jbvb7S2UNTjuncvbY0gBd?si=c26a4beac1374f89" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Damn!</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5M8I9TMeFrIRyg2EayfSMe?si=9b9278e512fe4b49" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Push</strong><br/>
                (prod. chris6lair & 1gokami)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2bAqe4MCyI5hIraDh21Myj?si=21b09ac2349a4b65" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Rockstar Bit</strong><br/>
                (prod. chris6lair & Rio Leyva)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0pqqUT0ou8XvJiRPfQkHAb?si=d9414e5e5e3a4568" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Buddha Buckle</strong><br/>
                (prod. chris6lair & Rio Leyva)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5MF37lXQbUTCwGVwyWcnkA?si=f36b18799a55403e" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>What About My Ops?</strong><br/>
                (prod. chris6lair & Rio Leyva)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1ujyULPJo9W3WU7m94JYrV?si=554eb00eb7c742ad" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>BK Seazy</strong><br/>
                (prod. chris6lair & bkthebagman)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6f5ToO1Wf8t2JHcSL2xw5Q?si=eb55c86747fa4f11" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Didn't Expect</strong><br/>
                (prod. chris6lair & rayfromniburu)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4oAMfvyn6kZiYLexiL3DCe?si=abbaea853fef4307" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Remember A Time</strong><br/>
                (prod. chris6lair & jibaorockin)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/19Vei21V2v8HCAZgJYdUng?si=a020216e0713472f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Bleazy Seazy</strong><br/>
                (prod. chris6lair & Rio Leyva)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6qT3dsDzgACUZFD4KL7idy?si=cb66aae5c3f549cf" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Hard Rock</strong><br/>
                (prod. chris6lair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7AaI7LJVZAKwJrRImEadYh?si=cbb64415534a4087" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-zNjezkg8qbFqiFKY-wPllXQ-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>That Classic Kawaii</strong><br/>
                (prod. chris6lair & Rio Leyva)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://soundcloud.com/kawaiiry/buddha-man" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-dRUzGXmrWMNzsqCS-ptfv2A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Buddha Man</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://soundcloud.com/kawaiiry/on-the-run-with-my-heartprod-ayjd" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-XMCr69Q528ORKH48-6EfVrA-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>On The Run</strong><br/>
                (prod. AY!JD)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://soundcloud.com/kawaiiry/go-crazy-kawaii-ry-remix" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-y18K5TcuBR1EARhG-GURckA-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>See Me This Weekend</strong><br/>
                (prod. AY!JD)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://soundcloud.com/kawaiiry/go-crazy-kawaii-ry-remix" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-8iiYyyOdD3AyCvio-yCdFnw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Chris Brown & Young Thug - Go Crazy (Kawaii Ry Remix)</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0ZhjcCHyPuFmA7Im2bECZa?si=5ab6d067ca5e4d3f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Security (feat. Boy Floss)</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0ZhjcCHyPuFmA7Im2bECZa?si=5ab6d067ca5e4d3f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Red Cups (feat. Boy Floss)</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2PmZg9Hr2agc4h5zhhUZWv?si=134a25f1aa734dbe" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Truie Talk</strong><br/>
                (prod. gotti + gmk)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/41aC2y6hZavKhHovO32NgT?si=99b6e56c83494acd" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Barqs</strong><br/>
                (prod. gotti & maniii)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4C8DiCFWnshpnsH62IJGwC?si=020951183192454d" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>True Stitch</strong><br/>
                (prod. gotti & 1montemarko)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1TLPOICGUuYBF9zb77R6hc?si=4a59278cb7924068" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Mind Freak</strong><br/>
                (prod. gotti)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/59xSey0MNiTLAGKOWK6VEi?si=49d9dfa802324842" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Ledger Stax</strong><br/>
                (prod. starsmp3)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0lMWZ9d0ETX4HvMs3Nw8Bh?si=177c73b9435f4969" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>M7</strong><br/>
                (prod. gotti & mafiosaucedup)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7epE1vkXxOxgnGLrcMWW1C?si=99566824f9db4262" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Know They Mad</strong><br/>
                (prod. Theuzz011)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5ijiVFyLilcNERuNNprPwY?si=08272fb666904393" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>2 Teas</strong><br/>
                (prod. Theuzz011)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/21nQhrw3UQCUEPYhuV7GCJ?si=f786654881d54ee0" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>FEMA</strong><br/>
                (prod. Foreigner2x)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1UAqn9h6QNbUt4RCZWLCXo?si=039f9a53a75e40b5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>HTR</strong><br/>
                (prod. Foreigner2x)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3cANchrZx8yxkxDgKyybgV?si=40e17f7bc7514c1a" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>No Luck II</strong><br/>
                (prod. Foreigner2x)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5zzHYf4ZFfOzqUw1xMa5tI?si=160aa927be004d7a" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Eye On You</strong><br/>
                (prod. Calcinha)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3hEyr112NkmziqWp5PWzlt?si=1140ccd52f1f4341" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>March 2nd</strong><br/>
                (prod. ANJU Online!)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3WgV2xLo977OTckMqhoG8P?si=cc5de26889d24c81" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>CG FEST 2023 (FREESTYLE)</strong><br/>
                (prod. Foreigner2x)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5TQVs07POHMCYZoBesoYAp?si=8c0de3493d4a40b9" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>CK 2 Real Soon...</strong><br/>
                (prod. Daria St. Clair)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5D7um3ETXSNz1VyW8zShKf?si=ab9954f820094c01" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Falling In Love (I Didn't Know) (feat. Remy3D)</strong><br/>
                (prod. Treyvonne)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5EpP5ZFLMZQmuaFMJTzyFr?si=96263ee6df43437c" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Get It & Go</strong><br/>
                (prod. gotti & says6x)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1hW6uB64NAGtWW5I4EHjnB?si=1371660248484abb" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>On One, Off Two</strong><br/>
                (prod. Vorni)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/536xYIcKKYIggbm585PP25?si=b9988b10bcc64698" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Fill Up</strong><br/>
                (prod. Rich Illuminati)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1jeY1KAGYUobEegT0LVy4x?si=5109dd14ea9a4bec" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Hope You Don't</strong><br/>
                (prod. Rich Illuminati & notamachine)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/42O5N4EjL8js0TPC2LsPsS?si=c3d2b6b3aa3042d1" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Your Hand In Mind</strong><br/>
                (prod. starsmp3)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7tb64UVOARyAbQyIReaR4n?si=b5f836548ba8417c" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Rockstar Wasted</strong><br/>
                (prod. gotti & notamachine)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4vLJq8MzoxKyrA8rjYj2me?si=79ae9a25c4374316" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>I Don't Wanna Know What It Feels Like</strong><br/>
                (prod. gotti)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7vOR2BTjA7B2XmR0uwlRb6?si=523aee8dce684807" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-Gu6OVaO4vhT4lHEK-GylG5A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>How 2 Deal</strong><br/>
                (prod. Leesta)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3gYybcy3knWclBAY1ZXond?si=00c8b59586a449fa" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-725aVzztbF5NzClY-oSS88A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Scarface (feat. Boy Floss)</strong><br/>
                (prod. Leesta)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3gYybcy3knWclBAY1ZXond?si=00c8b59586a449fa" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-725aVzztbF5NzClY-oSS88A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>ICU</strong><br/>
                (prod. Leesta)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1NhIITyyIuWRuV9tZSMBvK?si=a7702dc6ddbd4e02" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-725aVzztbF5NzClY-oSS88A-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Don't Be Mad (feat. Lil Dujour)</strong><br/>
                (prod. Leesta)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1NhIITyyIuWRuV9tZSMBvK?si=a7702dc6ddbd4e02" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Dear Momma</strong><br/>
                (prod. Jang)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3IhCkp0pe3KNpimBr5vWYr?si=75dad82cc0764a3c" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Late (feat. Lil Dujour)</strong><br/>
                (prod. Meauxminy & notamachine)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1icCgD4faTpNctyEekyYMv?si=55893922f3d94038" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Hideout (feat. Boy Floss)</strong><br/>
                (prod. Ezy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1DQ9ThPSUXBxv0Ee0WNN1w?si=d7fde544a2bd45b1" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Feelings In A Blunt (feat. Jackie Platinum)</strong><br/>
                (prod. Ezy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1zHJmzl14ElbLxrlXB70zF?si=0b730391539a445c" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Catalyst</strong><br/>
                (prod. ANJU Online!)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0m9D2o320oWeEbxJNsyvth?si=4860cc05b58b492f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>On One</strong><br/>
                (prod. Jang)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7K0WP1w9RjjbzR6ps6CQDX?si=abd15f15fa79429e" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Rollback</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7D3pMNDWWisZnL46wMnmTT?si=43d02a20c4264e55" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Pants Up</strong><br/>
                (prod. gotti)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0sMXIGaC3mGTFDCwpRrRDe?si=c045c82fff54453e" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Bump Bump</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2ehNRiYHZdKCdO6ReSIgc3?si=1b65b039fa8846d8" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Bobby & Whitney</strong><br/>
                (prod. Ross Gossage)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7qP4y4L1zqurX1hwQf9i9q?si=df958b9594be4fde" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>I Cry, I Bleed</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6KvVeWoh1SJGq85RDN10a6?si=1bc3631788884b3e" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>I'm Serious</strong><br/>
                (prod. Zann Scott)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5RATh9zMFNIEesfD1NgdZP?si=86dcc5ea607d4d39" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Problem Solved</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5pbkD7cBx01Rhz9Mjobncg?si=dc2b31b2a3d54eb5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>9</strong><br/>
                (prod. creedmakinnoise)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/619lrPufVTXXZAgY0GSmt7?si=f78d73e96bc7490f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>The Price</strong><br/>
                (prod. Foreigner2x)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1Gjfhb0Y1yyaFe1Xs3YTLo?si=bdfe8542895741f9" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Uh-Huh</strong><br/>
                (prod. geekinz)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4KJWTjFVHh8ImFAKM0TiLE?si=b5683e10d6044c7f" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Plus Six</strong><br/>
                (prod. domboi)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3UQ0YaQRaJilLw7MFRcJ4B?si=699e45a045a54c9b" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>I'm Drunk & Feel Like Sosa</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1CaxtuBnqdZA1ROdKEnQY4?si=da38d8fcf3de435e" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>I Did</strong><br/>
                (prod. gotti & antar)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3hjkLIJyKBC0h9irDicMOR?si=7f0c322649ef4b86" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Acoustic High (feat. Remy3D)</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0w8plXhfo0d9D3bJ44zkER?si=2c1bc6a2b8fa4b69" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Up 2 You</strong><br/>
                (prod. treyvonne)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5MaRZlJmON5M29JXgoR7bX?si=4735080127034fb5" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Stab My Back</strong><br/>
                (prod. Rome)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2CmEGCDURG3w0knJFKGAZC?si=b73ba36616e84724" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Rolling Up</strong><br/>
                (prod. Jang)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/2zHkayttQXcXAyXFnj4Zic?si=3f3bba50399f44a2" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Blessing</strong><br/>
                (prod. Era)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5o0DgXf5hdew0LWavGrD9G?si=7b6eed9d14974080" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Me 2</strong><br/>
                (prod. bleachh)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/61RoXcixxHz2mUh7eeOJLd?si=70c9796ad4de4828" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>One Time</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1a6AkDSELN9zj8LhcXnkAu?si=341722a5b10a40ee" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Love is Dead</strong><br/>
                (prod. notamachine, antar & 1wireshark)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6M7DgI8n3DR8zvo8cZ8oXz?si=c88fe36e5e174fd3" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>God Complex</strong><br/>
                (prod. notamachine)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5OHSYEMglbWtzCpZ6EHPiv?si=69d07519cfc847f4" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Chanel Rings</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6ZGe6pRUnZAX0zvTTcOS0f?si=36414ebe4fc345f3" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Get Right (feat. Tristan Martin)</strong><br/>
                (prod. ifeelvoid)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/3yKOQQEQ1qoDzITC714ho9?si=0f83cab5eccf4366" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Better Way</strong><br/>
                (prod. gotti & 1montemarko)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4tOwvPsCZE0MgxNfWWlRtS?si=acbd2110329a43ba" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Tailgate</strong><br/>
                (prod. Semaj)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/1PD6BB6aJxjaE83lA5idz7?si=3066a1dda564464b" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Tired</strong><br/>
                (prod. Curtains)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/25lHDEdpoIwdZQYR0cYYFN?si=4d4c64ed830c4d69" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Good Intentions</strong><br/>
                (prod. jp)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/0iRkxIFc5fMPlCeJjgik0T?si=c5606a3dfbb94fe6" target="_blank" rel="noopener noreferrer">
              <div class="overflow-hidden">
                <img src="https://i1.sndcdn.com/artworks-mNjoZfs4ncaHSVx0-83Gnmw-t500x500.jpg" width="100%" alt="" class="hover-zoom"/>
              </div>
              <p class="gallery-text">
                <strong>Long Dark Road</strong><br/>
                (prod. Saint Mike)
              </p>
            </a>
          </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6JPdZVcrAGL1jrIbSYWwOQ?si=2fcb959ae9e34cab" target="_blank" rel="noopener noreferrer">
            <img src="https://i.imgur.com/AIZYHuT.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry, TRYFG & Boy Floss - Rare Link</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/29tpLhE6PvwdYko6NrgT18?si=6a59d17f87214a10" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Telegram</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6oVpG2vpg43WsYG2na3m7c?si=dd893e2095464fb2" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Goto The Moon (feat. Boy Floss)</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0InUZQv6p4VpfR9ZRRiJ68?si=c284283d50bf4599" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Come Around</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6IT4Z652KDl6QswaZ7fQSS?si=7bc55faf55914baf" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>L2L (feat. Rileylol)</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3kxpNUWsWZUM8kUQ7X48us?si=99c28c69495741b6" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I'm Sorry</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/67T31MVYxb8XvdMFjGGi53?si=2e1eebfb45e04d11" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Cranium</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7lwIwXIuL33qNQQ1ibD5Ue?si=cca5b710f03e4d27" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Tapped In</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6LHQi6LysGr2gsHhddG0Fw?si=cb590340a80c4bdb" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Whole House</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/288deYC4uxPey0c6V1zX2C?si=d619f3b923f9423f" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Sweet Type</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2m5E7Z01E0oMrtqxUSUYhS?si=b6cedfc1e9dc4cdd" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Don't Try It (feat. rileylol)</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/312bqPDtMXOd5tWS1z2vEu?si=4bbcd81383b34426" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Why Do I?</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1h8hmTVelqWuuypBoLkiMI?si=75dcd1b4362f47e3" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>For You</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5ia1RNOp0es4kL3XcZnD9O?si=6c0736fc141c46a2" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I Guess I Gotta Do It</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0M4zWyxXS0q3BnRRTfUkpa?si=23c16d3883ed4d75" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nZHypsKCH9WPzk4z-44ObSQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>What She Hides</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0uO0Qosc5WYTfBjPi5x466?si=ded88831c62f4e41" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Clown</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3IAym27cx48RClaaVPghKA?si=b9532f9724fe412c" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Battle Bus (feat. Lil Dujour)</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/45eev9YzAniaNF9bgyqyn8?si=1017582b25774fc2" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>It's Me</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2BIrMMXBlknKNhscZOwrLG?si=855e5f8d22a44ac8" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Don't Wait On Me II</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4NpTXoV5k39TEPsxWUMaAb?si=54b25bf9cac04398" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Due Time</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2MAfiUDgKAhfvvD8GS9PUe?si=6e0edac5d99943d0" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Firefight</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4OTMVKBesbX2ro17DfE2Ke?si=0409e375d3354d11" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Folder</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7M98tFfeZZpPWghv4YTHoN?si=d0beeec085004371" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>My Weed</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5MFzQ5UCpnAYycgzybFkZV?si=595062485ebf4273" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Scoop</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/17ulPTVLeXnirBCGWvwp6f?si=c9416311568c4e8b" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Money Called</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3eD07yn0xIZCEux6CvequI?si=ad02750dffae4b97" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Twizzy (feat. rileylol)</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5HYuriuAaQLqXzOKFObaxg?si=76eba43232f44032" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Follow The Lead</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0tHeaM2h00oF2ENmetcIzV?si=52b864d9a54547a0" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Watches</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4XmqGJR6rmTmZFHXeK6czn?si=03ecd747da1d4424" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Yes No Maybe</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2mF8iIquaFdCK41bobEJuV?si=ad01060b0ec545b0" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Body Shop</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/57PCMEmvSTispedM3yZzn1?si=a7d22874325a40b7" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Would Your Rather</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2u1XRHa9HRGr5os98iTE21?si=68d6045ae23f4a81" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Stop! Look!</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4aZJiqQvn2EBp860EouMxR?si=a289c0fe4184483d" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wck162Bv3CofOVwy-oJLtzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Top Geek</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/60QeHdQVK1xoR8d4JEIUbK?si=5377300783af4d5b" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Lottery</strong><br/>
              (prod. Hygo)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2znkJL6RINFc0LOP9OAiHb?si=7913409f29ce4dc4" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bimmy Got Me</strong><br/>
              (prod. Picasso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0O0JKXmVtXg6dmhzEmOKFb?si=00fc8bd3c49b468c" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Wanted Dead or a Wild</strong><br/>
              (prod. ezy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7dj4Rf9bwDANLQLHQhycne?si=195758df3bc740fc" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Slime Cry</strong><br/>
              (prod. ANJU Online!)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5akPO8eS8O6JgxEZ8QU2XR?si=c6ed5bd8d33442d2" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>HML</strong><br/>
              (prod. S&S)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1oyNixV2h2TIYe6MoXLeqH?si=3b284aef1de14684" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>If Looks Could Kill</strong><br/>
              (prod. Picasso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1Yx3ow3qQKzyKXbGH5BW2M?si=361140afb52c4a0d" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Vetements Dance</strong><br/>
              (prod. Picasso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6PqdCvv3kuNg60alx0fyMh?si=81c499a820284964" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kodone</strong><br/>
              (prod. Picasso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3WCVPNkmcZf2Z5ieayWJ28?si=48a07d9489ef4d08" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Face</strong><br/>
              (prod. Picasso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6AlOrM7ecnefZ1eggWSBoh?si=dfd91bc6450e48f4" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>One & Done</strong><br/>
              (prod. Picasso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6NrS774Ihj0OXafBn0D534?si=40d955813aa943fa" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Memories</strong><br/>
              (prod. Picasso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4C1UNfTBBlsstOX9rzuApo?si=95915470b7b3435b" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>No Keys</strong><br/>
              (prod. Meauxminy & notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0vzQNOp7XkJbNQntszSXar?si=4d52b04c1c9b4f11" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-M0iURrDPpvvoSZT9-nWUIZg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>EVUL</strong><br/>
              (prod. STEFANO)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3Up1xuHDEH2P9fRE5BQwLU?si=9c0782b04e4e4ea1" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-M0iURrDPpvvoSZT9-nWUIZg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Junebug</strong><br/>
              (prod. STEFANO)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/31fRvMHvUOukArlWPzaBV7?si=4650516848324875" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-M0iURrDPpvvoSZT9-nWUIZg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Biscoff</strong><br/>
              (prod. STEFANO)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6DmrkzpD4GGTUhPzU9103M?si=7c670ea39aac4a6b" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-M0iURrDPpvvoSZT9-nWUIZg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Prime Day</strong><br/>
              (prod. STEFANO)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7HE0r3JDsMHtpfKH2EEl2C?si=2d5bc929c99545c3" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-M0iURrDPpvvoSZT9-nWUIZg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Prada Me</strong><br/>
              (prod. STEFANO & Remy3D)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6HSS8C7qTFp7xp4XaeVSYr?si=826231b058c24d70" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-M0iURrDPpvvoSZT9-nWUIZg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Realistic</strong><br/>
              (prod. STEFANO)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6XOdRRqu65ZxZJlNl72Bzm?si=0db04b58019a42c3" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-yfterL7ItIyXrKxb-1kOkBQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Horseshoes and Buddhaman</strong><br/>
              (prod. Remy3D & chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/special-k-prod-nextime" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-KrJUMZIniMPUu3Pf-p58Zew-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Special K</strong><br/>
              (prod. Nextime)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/32HZhtJOV3cj8iObJdadYj?si=e7031c7bdb5944af" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry & Lugosi - Hey Lugosi</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5Y3RT0cNWDm32BCFpEbCOu?si=dfd67e4cdf9f41f1" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry & Lugosi - 40 (feat. rileylol)</strong><br/>
              (prod. gotti)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1h1bV5x6Rqak2ZYNbc6JzC?si=0b1c59bbfcd64ba8" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry & Lugosi - FYBB</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6s1JmW3MXAb2BH9jFi8w13?si=604dfba0a31f48e2" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry & Lugosi - Remy Truck</strong><br/>
              (prod. Remy3D & notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1cBIAPbkt6Mb4qrbTzrvuq?si=cd741088348c4148" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry & Lugosi - Schoolin' (feat. Remy3D)</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0NzImhZKMO4N0YWy2gU0XA?si=be8a36a1e55f4ed5" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry & Lugosi - Oh So Fine</strong><br/>
              (prod. bleachh)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5MuS0w5MtGeXNSqo1WFVay?si=14d98e6e598047e8" target="_blank" rel="noopener noreferrer">
            <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry & Lugosi - Feelin' Old</strong><br/>
              (prod. chris6lair & toobears)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6kJndbk2XV3ExXBuIju5E1?si=a658f1fab25d4aa7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c2e40b9ca6e14145aecac252" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hunger Games</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/15F56AsbQwkY1hkYAB5oBg?si=144db226bd4646f4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c2e40b9ca6e14145aecac252" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Trap Gymnastics</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6fFYZXyoDkDaY8Q33VIj9y?si=908ce9922ab84624" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c2e40b9ca6e14145aecac252" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Darius</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4dRif5APRPfafnvcDpSo94?si=71151fef555b4065" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c2e40b9ca6e14145aecac252" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Scary Kid</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5pyZ9I02ByYwfKNYA4LRM4?si=0956d214cb70400b" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c2e40b9ca6e14145aecac252" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bleezy</strong><br/>
              (prod. chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6HhQeMgYZ1LlatIMASuH3w?si=7a71d453a3b94d31" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Backend</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4tig1jSQUwK67VvKhA2OUk?si=e54ca3c2ae4d4144" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Pocky</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/67jt4fzuZIK8JzjStDXL3N?si=7c7d03837a424c9e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Slippers</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2WChMqbDmN4ZNvTia8HpJU?si=f9ce53875320455a" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>ACSOM</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1Aru5QsDHrVu0mSejzoOJQ?si=9dc49be86fac4b11" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Appearances (feat. ReTread, Ivanko & Brole)</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/16NQubJdSSrjbjK2h4UPWv?si=25378694bab94477" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Circle Back</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/235GslmeWnm8issDYxeH1g?si=9042e42881d040ed" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Chop Walk</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4iHZ1g5m34xsqxqg92keim?si=58eb8de3a94e4f7e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Thrill</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0Od6jyoTFaPHyWGbaB8ox5?si=7b0afedc66874df6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Guild</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0ar12H8rKtrtFR8Tx3rkHO?si=438e9f5f35644004" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Credentials (BONUS)</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/last-weekend-prod-notamachine-joeleytrick" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-IEsDz7OWY1WNEHKM-kRQDhQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Last Weekend</strong><br/>
              (prod. notamachine & Joe Leytrick)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6g5skYYhAvM3RMocjkkKJs?si=55200d5073c14322" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27396c6aa05e224dd591203ac34" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Finally Here</strong><br/>
              (prod. dmtplug99)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6tOM6zohEDuiLYdPpT42j9?si=25a4fa5c03f24633" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27396c6aa05e224dd591203ac34" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>28</strong><br/>
              (prod. zukiing)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3g9T3on4AJcSgYhBVDAJRy?si=379be706a1d240be" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27396c6aa05e224dd591203ac34" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Who Feed the Block</strong><br/>
              (prod. k1llobyte)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/03Gls6kUhnpbjvlF8Fs6SG?si=ecfd3494f9964f25" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27396c6aa05e224dd591203ac34" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I Can Do Anything</strong><br/>
              (prod. k1llobyte)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0HGsxP3r9qiMycfPMzFb9K?si=aab63f8a93704ad0" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27396c6aa05e224dd591203ac34" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Not Just What You Thought</strong><br/>
              (prod. i_survived_2007)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5m8naUO9XQec8hU9Mrq8mX?si=9281ece995144b70" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27396c6aa05e224dd591203ac34" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Cat Eyes</strong><br/>
              (prod. jeepsy jim)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4NrqLUi6PWQAr94M3vATLf?si=8048b4d33dc349fa" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b27396c6aa05e224dd591203ac34" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bring the Bells Out</strong><br/>
              (prod. k1llobyte)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7yrtbZY3s5pIAD5efGYrWu?si=91db0d3ddb0c45f6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c14e90e6caa1072827e7a18b" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Can't Make Me</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/25pbPjFwrFcAdNuXtK56OF?si=f5c13c6340bd428c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c14e90e6caa1072827e7a18b" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Sorry, Again</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2BNyh64L9wNPn5bDrdcK0k?si=2f77d02eab804075" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273c14e90e6caa1072827e7a18b" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>You, Forever</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/flip-that-prod-culoso" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-xl7YZ8y3NXUoKl0V-K5Vj6g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Flip That</strong><br/>
              (prod. Culoso)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1nmmEsDbD37rdqfk08G5uI?si=e34e00060aa04bdb" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-aPzQtNEpZZ9vfZFQ-8gqmoA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>U2TH</strong><br/>
              (prod. notamachine & depart)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1q4Kf0FEfS3UXsXg9WzGyb?si=fd61dd37e4134be8" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-aPzQtNEpZZ9vfZFQ-8gqmoA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Coup de Grace</strong><br/>
              (prod. notamachine & kvse)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0AZeoMyTf3jX5fPqcLuRTO?si=f4271a5f0fa442d5" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-aPzQtNEpZZ9vfZFQ-8gqmoA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>HP</strong><br/>
              (prod. notamachine, depart & 1mvgnolia)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4qYH2UURO65Led2Sfq3B1T?si=0215f33a2f2b4316" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-aPzQtNEpZZ9vfZFQ-8gqmoA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hittin' Different</strong><br/>
              (prod. notamachine, depart & ryanbevelo)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7mOYd1ttL4T0yBdg8QvJrU?si=59815811729e4d0c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-aPzQtNEpZZ9vfZFQ-8gqmoA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hullbreaker</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4NPZqZsrp6oUx9nG64nnkk?si=c75a65f660724434" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-aPzQtNEpZZ9vfZFQ-8gqmoA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>My Cup</strong><br/>
              (prod. notamachine & kaishigui)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6GOYZrzUYyxqF7BTiXA99x?si=f73ee4566b494032" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-aPzQtNEpZZ9vfZFQ-8gqmoA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hope This Never Ends</strong><br/>
              (prod. notamachine, antar & jacktxt)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Music Video / Single</p>
          <a href="https://www.youtube.com/watch?v=0PDDyumLrYo" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-wDezjcyyNBP4WjQZ-zFdTCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>ANJU Online! & Kawaii Ry - SWAG SUPPLIER</strong><br/>(feat. STARINTHESKY)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/porch?in=kawaiiry/sets/bleezy-seazy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-w7LojHOCn7pzzJz6-5ChH1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Porch</strong><br/>
              (prod. Chris6lair)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/hellscape-soldier-prod-chris6lair?in=kawaiiry/sets/bleezy-seazy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-w7LojHOCn7pzzJz6-5ChH1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hellscape Soldier</strong><br/>
              (prod. Chris6lair)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/belt?in=kawaiiry/sets/bleezy-seazy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-w7LojHOCn7pzzJz6-5ChH1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Asteroid Belt</strong><br/>
              (prod. Chris6lair)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/b2dc?in=kawaiiry/sets/bleezy-seazy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-w7LojHOCn7pzzJz6-5ChH1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>B2DC</strong><br/>
              (prod. Chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/kiki-krazy-prod-meauxminy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-94Pwt3xAqVIRPzIA-rbwgLg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>KiKi Crazy</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/cant-believe" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-N9Ay8ThifqkFOruy-iurflg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Can't Believe</strong><br/>
              (prod. notamachine & prodmitchell1)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/smokin-ganj-in-the-saint-laurent-prod-notamachine-depart-lodoni" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-se0MVGyDOi849ltY-53i1DA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Smokin' Ganj In The Saint Laurent</strong><br/>
              (prod. notamachine, depart & lodoni)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/army-stickprod-gotti" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-nJg0yEUAy7SywuSK-4EeGrQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Army Stick</strong><br/>
              (prod. gotti)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/eleven-prod-notamachine-nightmare-freezepop" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-kThfbKvF8b7190lf-OusyVQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Eleven</strong><br/>
              (prod. notamachine, nightmare & freezepop)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/flawless-prod-ezy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-BskyiNlQWC99Xmh4-fTJmyw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Flaw(less)</strong><br/>
              (prod. ezy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/haagen-dazs-prod-ezy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-znFnFVktPrjveNGf-fzzROw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Häagen-Dazs</strong><br/>
              (prod. ezy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/trunk-prod-malloy" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-ynaqd74XgLbY0jib-UMYkzA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Trunk</strong><br/>
              (prod. malloy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/it-happened-prod-pand" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-S9ei2QinDvtuySMZ-uP6PUg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>It Happened</strong><br/>
              (prod. Pand)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/fruity-peebbbles-53122-455-pm" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-KKiZ1NmLLzlGvNPx-o64N4Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Fruity Pebbles</strong><br/>
              (prod. notamachine, depart & dynoxmusic)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/off-it-prod-notamachine" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-3oINC0l3ccyLiIg2-j7IoTQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>OFF IT</strong><br/>
              (prod. notamachine & Baredex)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/motorolla-prod-lathan" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-5q5YaHItkYa2DXSK-nsq0YQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>motorola</strong><br/>
              (prod. lathan)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/ohsht" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-qSLC3zjDthTJyU2N-oz88eg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>WDYK?</strong><br/>
              (prod. Ty David)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/its-working-prod-notamachine-gotti" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-yp6bmxZENq3T3t0c-cgzXAw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>It's Working...</strong><br/>
              (prod. notamachine & Gotti)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/justlikeme" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-Vy6XBVY419Qz5sBo-I1oX3g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Just Like Me</strong><br/>
              (prod. Chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/drop-my-racks-on-the-floor-prod-chris6lair" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-je7MQTeppbGdBmDx-MbOc0g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Drop My Racks On The Floor</strong><br/>
              (prod. Chris6lair)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/kawaii-creed-demo" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-mYqvkAizT0LtxI5W-uzb4Qw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Tune Off</strong><br/>
              (prod. creedmakinnoise)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/2foul-prod-geist" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-jMSIBqNEcswHruhM-YwmTug-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>2FOUL</strong><br/>
              (prod. Geist)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/chill-out-prod-sxxxxn" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-UMw0t83UkfxtT9nq-kuVLqw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>2 hands</strong><br/>
              (prod. sxxxxn)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/leave-prod-frozeine" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-8v3y09qPpEIq24HT-LbDHIw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Leave</strong><br/>
              (prod. frozeine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5WcMFgBGwAprkavtzpgT0d?si=d7750df71d3e4001" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Dujour Speaks (feat. Lil Dujour)</strong><br/>
              (prod. Meauxminy & Geist)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1SeRmotpPjvM8LxLiA11Rj?si=007f074c964a445d" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Sacrifice</strong><br/>
              (prod. Meauxminy & Geist)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7mUAYHUkJULXItbZoliZRH?si=7846219685714fe8" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Maybe Next Time</strong><br/>
              (prod. Meauxminy & Geist)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6MSlRI0ke0lTBhfWHWA3r3?si=7b06d3ee01b149e6" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Spriggan</strong><br/>
              (prod. Meauxminy & Geist)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1SERFTa6ndjpwY4b1fO4IB?si=7adc599a94dd46bd" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>10/31 (feat. Boy Floss)</strong><br/>
              (prod. Meauxminy & Geist)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1OeOaYFIieCjVVn2Yl2Qxh?si=e01a3bf087db4d9e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i.scdn.co/image/ab67616d0000b273f8f425ed3f229026f4806269" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>ANJU Online! & Kawaii Ry - Mr. Drill / pist off</strong><br/>
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/silence-open-amincmaj-151bpmprod-notamachine-joe-leytrick" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-Rx9sm1kcWYHl6eLY-LYuD0Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Silence (feat. Jackie Platinum)</strong><br/>
              (prod. notamachine & Joe Leytrick)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/fiend-prod-semaj" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-vvfmSRs9Jph3PnDo-mlFLyA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Fiend</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/queue-timer-prod-semaj" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-lOBaQWngDe7OMz4a-tx7GKw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Queue Timer</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/claim-up-prod-remy3d" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-aRhN8syAckyuyBBz-fio3AQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Claim Up</strong><br/>
              (prod. Remy3D)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/cutting-the-net-up-prod-uglyboy?in=kawaiiry/sets/ugly-tape" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wckpAbEaOz0AbvyM-odUPvw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Cutting the Net Up</strong><br/>
              (prod. uglyboy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/executive-prod-uglyboy?in=kawaiiry/sets/ugly-tape" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wckpAbEaOz0AbvyM-odUPvw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Executive</strong><br/>
              (prod. uglyboy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/ugly-ry-demo?in=kawaiiry/sets/ugly-tape" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wckpAbEaOz0AbvyM-odUPvw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Mothra</strong><br/>
              (prod. uglyboy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/tea-time-prod-uglyboy?in=kawaiiry/sets/ugly-tape" target="_blank" rel="noopener noreferrer">
            <img src="https://i1.sndcdn.com/artworks-wckpAbEaOz0AbvyM-odUPvw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Tea Time</strong><br/>
              (prod. uglyboy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/the-spot-prod-notamachine-horuhe-san-mitchell?in=kawaiiry/sets/one-left" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Q4zox4LUoIM5aTyY-vfsBrg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>the spot</strong><br/>
              (prod. notamachine, horuhe san & Mitchell)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/empty-the-bank-prod-notamachine-horuhe-san-beat-nutz?in=kawaiiry/sets/one-left" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Q4zox4LUoIM5aTyY-vfsBrg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>empty the bank</strong><br/>
              (prod. notamachine, horuhe san & Beatnutz)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/dont-wait-on-me-prod-notamachine" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-QbXT8DAWeJKslcgo-p9zfcA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>DONT WAIT ON ME</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/6-days-prod-bleachh-x-notamachine" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JV5gTbocgcCq9Jzm-3ti4zA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>6 Days</strong><br/>
              (prod. Bleachh, notamachine)
            </p>
          </a>
        </div>
     
      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/queued-up-prod-notamachine" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-maT9lRt2BBtnMwg3-jZ4VKQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Queue'd Up</strong><br/>
              (prod. notamachine, Depart Beats)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/5t4r5h1p5-prod-notamachine" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-maT9lRt2BBtnMwg3-jZ4VKQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Starships</strong><br/>
              (prod. notamachine, Itsjohnnyvio)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/love-in-the-air-prod-notamachine" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-maT9lRt2BBtnMwg3-jZ4VKQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Love in the Air</strong><br/>
              (prod. notamachine, Adam Eazy)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/eyes-locked-no-words-prod-notamachine" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-maT9lRt2BBtnMwg3-jZ4VKQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>We'll Run Past the Trees</strong><br/>
              (prod. notamachine, Joe Leytrick)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/eyes-locked-no-words-prod-notamachine" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-maT9lRt2BBtnMwg3-jZ4VKQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Eyes Locked, No Words</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/doubted-on-me-prod-notamachine-joeleytrick" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-hhN7CKiYzgb6y8rN-z2Nwag-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Doubted On Me</strong><br/>
              (prod. notamachine, Joe Leytrick)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/ladder" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-jGHURlKWau5V54WY-vbxzOA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Ladder</strong><br/>
              (prod. notamachine, Joe Leytrick)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/more-prod-notamachine-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-peLtxGRRN3BvaKbZ-cqJr6w-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>More</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/paper-cut-prod-notamachine-kvse" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-L7bPe60si0Uq3APA-dq22gw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Paper Cut</strong><br/>
              (prod. notamachine, Kvse)
            </p>
          </a>
        </div>

      <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/who-got-the-loud-pack-prod-notamachine-bleachh" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-I59BrE7dJeupJIL2-7yzyeA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Who Got That Loud Pack?</strong><br/>
              (prod. notamachine, Bleachh)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/creed-prod-notamachine-x-kal" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-ySGDb77ncikjS3Kg-G0K2wQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Creed</strong><br/>
              (prod. notamachine, Kalpierce)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/cc-crazy-prod-semaj" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-WT8UfTTW3ce7WZPk-MF1bmw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>CC Crazy</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/gremlin-prod-notamachine-gotti" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-U6SOdW1tFlB0pcSw-Ly4zwA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Gremlin</strong><br/>
              (prod. notamachine, Gotti)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/crash-it-2-prod-wound" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-AFS7gLNHCDzsrluL-GL5WLw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Crash It 2</strong><br/>
              (prod. Wound)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/killmilord-freestyle-prod-foriegner2x" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NHMUEUaj0ew3bunu-6aFVbw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>KILLMELORD FREESTYLE</strong><br/>
              (prod. Foreigner2x)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0mybs7fQVAE32dof9XgAh5?si=8f66a8e9738c4b4e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Everyday</strong><br/>
              (prod. Voltic, Thislandis)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1fnXKLL9KimuhaWXGaWJ7L?si=cf32102e4e1c4ff7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Pocket Rocket</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0u7CkqJAJfU5GWJghcGo0F?si=828908c38d0249c9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Faceless</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0u7CkqJAJfU5GWJghcGo0F?si=828908c38d0249c9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Depression & Weapons</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5ZzifrnAgHhaGaV9errGJE?si=2f0c82c0e2574cd7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>For Sure</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6O7xeAPiTpEVZfqIhRO1wt?si=4c83aff82dc54c13" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Trying For You</strong><br/>
              (prod. Aymn)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/fake-friends-prod-aymn" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Fake Friends</strong><br/>
              (prod. Aymn)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0VapOghBISw2SbMoPEEmmB?si=cb9482e9f9394187" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Mojo JoJo</strong><br/>
              (prod. Aymn)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2XluvJYRDQY5IuQHcgDbKM?si=17ed282ebdcb46dc" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>You're Done (I'm Numb)</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/37L5hH4o2rwZyc6yYFwau1?si=86d31b10ae5b461f" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Thought at 4AM IV</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2rnq2CHQSBJtV4CZdqmNCU?si=5426352e5f004fd9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>How Romantic</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/50j7SvFmVEOL4UT19VlryO?si=6bedf12e750d4621" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Makes Me Sick</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3DOaOX1AODmHOy7eflxg5D?si=6deae251adbb4951" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-VKox1o1QdhHLOGSk-9Eg0VQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Walker Texas Ranger</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2TgcJquVo5kNa8uG2YcCnt?si=41ba12389e5d4d88" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Touché</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1VIXONQbUwV1WNdHexT0r2?si=9358f708604748be" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Restart</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/261ls3bLUNQNdPiHEBrv2p?si=ba52d54e54bf4c75" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Late Night</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4UpWvmngyhuFMNAfoCr8W1?si=de6dd99101924baa" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Romeo & Juliet</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1zBUX5VK4Vot6MWjTyA6i1?si=314e0d5e1c8c402c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Love Kills (Time Heals)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4winwPkQZ17ZuLilT1u4ya?si=22a4a3d8264b4977" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Keep Me Safe</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/22P9XDIPK45Lsi738qJ6NH?si=22ba3de16ec64d44" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bloodloss</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/045Lyssqnyxo38RPI1vtyS?si=41f98c0e914447c4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Right Time</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2rE604PA28rXOqjK3IIJur?si=129349363f694dd6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Laid Back</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/78tOzpKgBiXvRxtP3XsrGr?si=276e642c9e724ce5" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Crystal Ball</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5OZKTrLFiYeCUsbKdP9p5u?si=a8e4cf1235d9475d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Closure</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6w5cRaDfeyuw2WXwyB7Hs9?si=69482585257d4a7f" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Thoughts at 4AM III</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4W1kZZP01KOgfabsGBi83C?si=5727f9d7b8594a0c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Under My Covers</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0yHBJltb87n0PRjMZ98ug2?si=c778bf2178984136" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Misunderstood</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3cMwuaNDdUgPK2bwhiqIAJ?si=ecebd74820704144" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Funny Thing</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4PuSCGTBmdwIy85EAxZeMx?si=cc41c243c02c4572" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Heaven Sent // Bringing Hell</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2uhd0YNuMImu42dhEuB4DD?si=4758e75dc2984c6a" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Forbidden Love</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2MOLWYHb0S0eQOLsk1Cv9a?si=7363a8bd962c4fb6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-2kPKy1Oypyd1wBgT-r0kSZQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Love Robbery</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3q0DOgcONZgq1uKY4PWnP6?si=a83f947723254d6a" target="_blank" rel="noopener noreferrer">    
            <img src="https://m.media-amazon.com/images/I/41JWp5tzeEL._UXNaN_FMjpg_QL85_.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Thoughts at 4AM II</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/09xp0Y5oVvBNkEYqo5QJW8?si=76d82a5d113c456d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>i was scared of love... would i ever go back?</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0GlqMzPZT2T9HLkHJKuce0?si=69016d7790cf4933" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>thoughts at 4am without you</strong><br/>
              (prod. Valious)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7nKoIh4oRcxJjwubpUZGkX?si=8bc750f0bbc9471e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>i was made to be broken</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/60OlQ6H7j6SiTMxATK9jzj?si=5ecdba71a1f64989" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>dont play</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3zVtZOp9VuZMznJmFi9aAn?si=97219e30e0b74429" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>sixteen oh four</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0nhjIuh4LXbKQSwgleDoXw?si=959037565d284f2c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>day 2 day</strong><br/>
              (prod. Fedia)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0ZmRrCSzKeb3V2lp9orfm0?si=e1c7b04521db4786" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>flexed up</strong><br/>
              (prod. Valious)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3JgFOUJ9Wf9hgYKuiTx3O7?si=282f46548bf64538" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>heart cold (feat. Remy3D)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0kiZLbEx9UqDwpAchishqn?si=9ce34465862b4aea" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>deadman wonderland (feat. mathieu!)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7tHt2vB1GePmUOBsX932DP?si=84a8091dfdf84082" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>lately i just hate/love us (feat. Lovesickbray)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0W0Ly7VoZER6ndZA3Xu7mz?si=13879c5edc624ba5" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>thats right baby</strong><br/>
              (prod. notamachine, Uncle Beat Productions)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6Y1NuHAmUtv2U5VN7qB7GK?si=05ffd12f160d4ac4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>beautiful heartbreak</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4frDr21h0XyFWYMQFaRfLd?si=1875b75fab8b4d36" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>hellscape</strong><br/>
              (prod. Bleed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/07l0CzDWUaXY92KMQyl1BA?si=8fd23d53209445b6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>jesus jesus</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3N2wOuS5ZLiw73XahOH4TK?si=41f8d77acd9c41b9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>do you feel it?</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/20no6a1uHVilM0YuJmL0ep?si=65df4526c0d640ea" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>made of glass</strong><br/>
              (prod. Eddie B)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5xREFgXgcEkgJxdmbFaYfF?si=4d59aac11f784f2c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>obvious</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5KD8KacsVrnRgrIsmNhQTt?si=9528e5ffa2304123" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>lugosi in a ghost (feat. Lugosi)</strong><br/>
              (prod. Fedia)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/55pVAmXrwyFiBr9tKSCHVQ?si=8e237705f25c48bc" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>fall into the crossfade (feat. Semaj)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0CIy0w5uFYqrDLHZM3CE0e?si=703fc4f40ce64bd0" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>aqua's interlude (feat. Aqua Aura)</strong><br/>
              (prod. Cadence)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/64J9zNPV5quWjyKK6UZSN7?si=d7c359f3e44442b7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>lately you aint calling me baby (feat. Uglyboy)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5B1szQkdfkl2Sm9bNPAY4i?si=0793c053ecb24399" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>we all change and thats okay</strong><br/>
              (prod. ANJU Online!)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/hellscapedigital/camo-croc" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-M65REbxvpBKAYv1s-c35jbg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>ANJU Online! & Kawaii Ry - Camo Croc</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/bottomless-prod-semaj" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-DhryXJnqHyStjcoY-Ujivig-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bottomless</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/20ZvpeJuz3Nv6VCwjpYdxu?si=736967b16df64b7f" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-t4xSwCgh2Slp1ZDi-MjaU2g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Shoulda Coulda Woulda</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6Q3CMGaJ7U4RYaMercDoVB?si=8ee5cde9ef4a4ceb" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-t4xSwCgh2Slp1ZDi-MjaU2g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Elevate the Sound</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0LaWojZpVqT4W9vE9yItEv?si=8844be206cd34705" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-t4xSwCgh2Slp1ZDi-MjaU2g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Love Me // Hurt Me</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7mifwW2B9Xz7OkxM5sDQQs?si=5eb6c8fbdeb341af" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-t4xSwCgh2Slp1ZDi-MjaU2g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Genre Bender</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1v55mPUMBGvTo5BNFzOpyI?si=32b33f8e937d4f5e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-t4xSwCgh2Slp1ZDi-MjaU2g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Switch it Up</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/pop-out-prod-notamachine-joeleytrick" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6W9ZxX1f6ftK1kyN-QJvKjA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Pop Out</strong><br/>
              (prod. notamachine, Joeyletrick)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/bleach-on-twitch-freestyle" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JAs6yBoamfBk48tJ-0H69Zg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bleachh On Twitch Freestyle</strong><br/>
              (prod. foreigner2x)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6BiCsa0VSNWsylxyvxKI4P?si=d5293ba9c0a644fc" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Switch Lanes</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1HjF7iWVJ2Z7VqjWOnLWnY?si=c579581eb5da4997" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Few Thangs</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7BngQc0OFivT9wWEWB8dzS?si=bb0e3e00013245fc" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Tidal Wave</strong><br/>
              (prod. notamachine, Joeyletrick)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7HUPQHAdzMFDMNnw5i5pPO?si=7da0e4720138453f" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Best Place Possible</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1J83kVNUWGSct6AH5mEZwZ?si=f895739cc1dd407d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Locket</strong><br/>
              (prod. notamachine, Depart Beats)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4Yw58pJXkBwc9PfIlwSNz3?si=dd248518766e4efc" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Ah Ahhh</strong><br/>
              (prod. Uglyboy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/43dwCnV2oKixkx44FfeLns?si=a9010189fdd2477b" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Waterfall</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/50whAVwGRa84gDZPEF5z5K?si=df49506bc873478e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6yTCkFzKMGrNXQz9-zTvYtQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Royal Flush</strong><br/>
              (prod. notamachine, Joeyletrick)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1IgoGT7dj2oTXA4YZVODJA?si=4bd9c99aceb84c07" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Spin the Bottle</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3NRCZhLqrcK3xtCEDPNO06?si=1cd027ef584647f2" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-yoWgzLvM7OfVdYLz-BLUdWA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Fire Starter</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1ZKLQPmWsSy8HDuEEj7tGR?si=9db842624dcf4abe" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Fit Check</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0NRlAyNJhISE8HfZ3Jxql5?si=d62a5bf738bf45a8" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Deez</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7CBBTbfW4oiRTkYBUHqPZY?si=b43d0a012b7d4e48" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Same Things</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7J9FBeJzca8IrhPwiXsvyk?si=844eede9a7e84cf5" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Opinions</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7bWLp7NsNEn2ZZeQQFXP0u?si=5486c2ac35f84b7d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Wedding Day</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5YiEqFlppGCyV3KCzHSwj7?si=2d7c8862b7e04863" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Atomic Bomb</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7BKeNI2Gr7ZBUKGiRbUqFa?si=35297216a1e04818" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Trench Work</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3cSwaaIPAMw4w5cCRwnkQI?si=366451f5bc244451" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Quotables</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0OsHHyRRWUYZx8G6OglCKi?si=80efda0b7eb34fee" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Blah Blah Blah</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7yGNcIjgierQWr2TSUk4D7?si=4890256e423a44dd" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Stratosphere</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6NLSjKyxmekdsMu3x40dlj?si=b931a4913fc5484d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>System Overload</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/67g1XLhbARovhJOYVLnZ8U?si=0bdc4341463b46ce" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Sensational</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3DqzebhDxh2QGwGKqGnzkT?si=540066871dc44250" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>No Love</strong><br/>
              (prod. void)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6CZEfLlfcdeCxBtYoVeGbf?si=77c0e8bac5b14792" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Nothing Into Something</strong><br/>
              (prod. void)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6nSiEvusOS56yQRawiyA5q?si=f694a2ac94f84d50" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>GBA</strong><br/>
              (prod. void)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2sOB9t9mBKOR3P9qoBslUW?si=804894be97fe447b" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-NTwHopk3sf8zsJT0-z1BdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Stepping Stone</strong><br/>
              (prod. void)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/burn-me-down-notamachine-remix" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-S8eeBrZFa7LCL6FE-awy4YA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Burn Me Down</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/tears-on-the-dance-floor-prod-nvki" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-hRPgU3H7V0cm7tfB-2X043w-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Tears On the Dance Floor</strong><br/>
              (prod. NVKI)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/lootd-up-prod-semaj" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-BiATc3nFWxJ9wGTD-j0kNaA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Looted Up</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Cover</p>
          <a href="https://soundcloud.com/kawaiiry/novacane-cover" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-YjPisgBKtbvPmVem-CqyzpQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Novacane (Frank Ocean Cover)</strong><br/>
              (prod. Tricky Stewart)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Cover</p>
          <a href="https://soundcloud.com/kawaiiry/can-you-be-my-friend-cover" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-FRzhYjil9qUpfyl9-HUSOZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Can You Be My Friend (Chief Keep Cover)</strong><br/>
              (prod. Young Chop, CBMIX, Chief Keef)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/scoreboard-prod-notamachine-couple-milli" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-mLSl1cRhmwiOLeZq-J9HyUQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Score Board</strong><br/>
              (prod. notamachine, Couple Milli)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/flawless-prod-loschu-beats?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>FLAWLESS</strong><br/>
              (prod. LoSchu)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/vetements-prod-couple-milli?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>VETEMENTS</strong><br/>
              (prod. Couple Milli)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/jammer-boy-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>JAMMER BOY</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/funds-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>333 COLOGNE</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/dizzturbia-prod-korragon?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>DIZZTURBIA</strong><br/>
              (prod. Korragon)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/no-smoke-ab-minor?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>NO SMOKE (feat. Lugosi)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/slaughterhouse-prod-korragon?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>SLAUGHTERHOUSE</strong><br/>
              (prod. Korragon)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/fever-dream-prod-starsmp3?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>FEVER DREAM</strong><br/>
              (prod. Starsmp3)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/soul-dependent-prod-donky?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>SOUL DEPENDENT</strong><br/>
              (prod. Donky)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/idk?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>IDK</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/youll-see-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>YOU'LL SEE</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/loving-you-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>LOVING YOU</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/calling-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>CALLING</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/dont-love-me-for-clout-prod-semaj?in=kawaiiry/sets/kawaiis-archives-vol-1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>DON'T LOVE ME FOR CLOUT</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/walk-in-closet-prod-semaj" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GlX4ANTrmFCQA1I5-AusZ1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>WALK IN CLOSET</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/runny-money-prod-loschu-beats" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-oOCUgMQn515TCpUj-u3kKgg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Runny Money</strong><br/>
              (prod. LoSchu)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/electricity-prod-jang" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-FBsff6TmezCv7NSs-6SNXxg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Electricity</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3u1JdCwdmP04gZOpOCmaNU?si=07ab2211c70940c7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-EsnzJHwsi1a6bU7B-3hVvnQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Element</strong><br/>
              (prod. notamachine, realayvah, hayleeswrld)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4S2RqsFNrCXWkd51LTQ51u?si=d8f4b221ee14476f" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Pretendo (Disscord)</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0U4G95Mzz8GEiv0ppHDSJ6?si=93c8e4f77dca4897" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Schwacked</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1HWUkxyowLO4Mp7pKY9qwa?si=18ad028153134d18" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>F-Type</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4g5b1CGjeAZuPQAbkfi49v?si=e7096044fe2e4016" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Rainbow Road</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2ZgnApTSp0oWnJSVZAHaWM?si=7c62ed681694487d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Red Dot</strong><br/>
              (prod. Killheen)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2c5eLBJrxHHfQTlhOzPgXB?si=8652ea4fb03d4acf" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Toad Arc</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0oNuOMjhXNCodSLOT2HkpY?si=b970c302f39e4732" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Gotta Go</strong><br/>
              (prod. Uglyboy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/03EEiEEis94QhNevddJZKN?si=4c63800eac564744" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bye-Bye</strong><br/>
              (prod. Sorrowbringer)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/08hPcgX8YjZNHO6i5MfoZ7?si=2464318383ea44f5" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>The End</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/75GhpmjN68kAJF0NBQtPyt?si=6aa456e057974a32" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Levitate</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/52UoxhW2ykTbPIhiZ0OS26?si=3978dda2c3fa4122" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>More Than Hate</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3ezlM7TGgIsq7GVl84OA83?si=3a24c8e5f3f74ed4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Demi Lovato</strong><br/>
              (prod. notamachine, Couple Milli)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/06JqQxCOz5IKZLLqgiIJDD?si=67d5d29a42f74ace" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Wrecking Ball</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5FigJ1pp2FlhPqjZu1xPqP?si=9f90acc8e4d94aae" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Stormy</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0XRLezZmCnddgtLAxIKAyG?si=4c98d3c525fb4d71" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>In My Glow</strong><br/>
              (prod. Donky)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6X7EID0maKLLRh8HzFTzp7?si=d05aa6f5ce8b42a1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I Don't Want This To End</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3S93HLTSA5r5aF8sRZzG5q?si=5061c18cb0184192" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Think About Me For a Second</strong><br/>
              (prod. Semaj, notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/03FCS2Mj1IlBviCaUzbsj8?si=d71cfe6b504840e8" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Exit Life</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5L98AbzZsp4iZmPezrlQwr?si=b8a7644917a545cd" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hugh Heffy</strong><br/>
              (prod. DEEGS)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7DV5wudkqw4aIieASNxbxg?si=7ef607583de8485e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Credit Cards</strong><br/>
              (prod. DEEGS, Cartoon Ben, Melting Slowly)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/30ikBKFahf1a8x82ROCK2D?si=1a56a5663e084d8d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Can't Keep Up</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3S5CUlVp3jEVk7kJpvP3N3?si=504d8e3e6e1b4c6c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Suite Life</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1incJfp7SNou3twKGiJgua?si=5b3c602f1c544be2" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Open Door</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5gGfAeJhWS8DawnFR7oin7?si=7d74df34f0784756" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I Just Want Forever</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/732MJs62ktGT7yoza0SWVW?si=84faf233856b4cf0" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Nice Fit</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5rb5WA27YIIBORmqwo3V4U?si=8abe7d2200b24a07" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Take Your Time</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1VHeipx0QDCCsZrsybfL13?si=8d81b28e4c304063" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Pretty Girls</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5f8n3biiRHtKiH53bwS4mS?si=f651aeab02f34761" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Love Scars and Broken Hearts</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/055Vyu8DSSKy6UTtmt1bbK?si=ed514e8d2e1d46bc" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Out Of the City</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/25vjTqppKMuoy0BzjE1Qd8?si=cd91a62222bc462c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Paint the Picture</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7ieo2NFP7JvKOvA3dlVl2X?si=ffff8e0c587f4ecf" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bad News Bears</strong><br/>
              (prod. notamachine)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0SF05Z7W9Uegz34QBF4kyo?si=649ce3197c994760" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Nothing Like Your Old Girl</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0kbuD96UyLQUgJ1jkYFVi9?si=29f51b45b2be4011" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Days Off</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6J8EV3qFmpYIm72K9QIzT0?si=865666293b2247c4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hellscape Zone (feat. Remy3D)</strong><br/>
              (prod. Sorrowbringer)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4VqjBT3mbx6qsP6jTHNSL2?si=fcd2def431cc467e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Speak Your Mind</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3iZcItr3ZCP6UDatPNB7Ae?si=a1f078f44a084227" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>No Paradigms</strong><br/>
              (prod. Cobra)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6wurWPoan6Z7DxXnx9igUt?si=81de61c72d15494b" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Siamese</strong><br/>
              (prod. Zach Sutton)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5Q4K4CVyLedSr7Tyxpi1X2?si=46912b3841544605" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Slimeball</strong><br/>
              (prod. nnovad)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/44Zx1SOpcArRdYxBtSsKDj?si=2af8bdbe435043f5" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>But When You Get Home</strong><br/>
              (prod. cashoutbernard)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/11quiOkuOVgkoy7DTjmzVx?si=91d39c2a9cd34eef" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Off The Leash</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1gc6YW1UHVhSVcNJbcrdXI?si=2db5e6e501994bf7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Twin Twin</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0cZjX9dvc26WO1XvznWexm?si=a7e361b2782f430c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Mystery</strong><br/>
              (prod. Semaj)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6tkhw84SIzS6tCELL7VDN5?si=e1cf30d79d8f4c31" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Papparazzi</strong><br/>
              (prod. 13Connor, 100k)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6CWo5prwYmmRWsoc3Yfaqz?si=8d50dea961044df9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Go Go Go</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/572A6YPDFX2f1sQ01PXMYu?si=b357ce3185714a22" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-y6kYvvJF00JPoUKT-GEzVRg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hell Hounds</strong><br/>
              (prod. notamachine + Gotti)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7fo6uNuJy44cvo53Z3i7yy?si=2b114447c94b49f4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-5OrasGYY1xOzAcLr-3szYFA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>59 Fifty</strong><br/>
              (prod. Eddie B)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5ITyPVjLmZtaVinPLOVQuB?si=ec0fc97327a64f76" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-ZR9ZSw7H2uPKkn46-EYmZRw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>RIP Mageezy</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1H6sWUuCsiykfAeKfTh2UL?si=1bf24ae396de41e4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-5bjONhx1tMyB9MZM-BDEfyg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>One Piece</strong><br/>
              (prod. Kretty)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5bLxC2dbY08JUmXCJUwKtN?si=2b3682df88f44308" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-5bjONhx1tMyB9MZM-BDEfyg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Fiona</strong><br/>
              (prod. Draco Murks)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2mf55xNpDgEAiXoNy7FfmS?si=15395b619ba14357" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-5bjONhx1tMyB9MZM-BDEfyg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Pay Me Homage</strong><br/>
              (prod. Kretty)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0msDL2heGvFRlXKrgCIX1f?si=d59cc5824a584d7f" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-5bjONhx1tMyB9MZM-BDEfyg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Karen</strong><br/>
              (prod. Kretty)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4MuKUDOGoJRKzTvppewDXm?si=7084b29904fb47f9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-5bjONhx1tMyB9MZM-BDEfyg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Red Rover</strong><br/>
              (prod. YK)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1A5hZCwBN4qXFCc7kCpYix?si=ed0ca260df2a4ea3" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GZYD1DclNPKMyjbo-zzLQdA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I Wake Up</strong><br/>
              (prod. Ricky Rage)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1Ck2oOR4iRvWHtnqEVmlAs?si=8223e181465449d1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-3zsvvwaYr23DyBgc-bOKz1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Bad 4 Me</strong><br/>
              (prod. LukeyBeats)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6pIba5jumGSRzDkcYriVQq?si=dddd79904cb94d26" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-MS137Uw7SmtzePAK-zdq7OQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>ILYT</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5bnojcOGDT01uDjhXs3Lud?si=e6260e7fd62c4747" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Klfh7W2EfXi3Z7LV-61xyaQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Peace</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6KentdX28T6AR6RzWQTaDi?si=c787e039ad324cd2" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Lagged Out</strong><br/>
              (prod. Dabeast Beats)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3Pu4JB8hXOc8JVK3rZg01e?si=f95bf20ff6824918" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Falcon Punch (feat. Remy3D, Nyquil)</strong><br/>
              (prod. Dabeast Beats)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3vncHGYawGWiUSvJ4Wb3Z1?si=b1ac70fa0eab49f8" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Chaos Emerald (feat. Nyquil)</strong><br/>
              (prod. Dabeast Beats)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5eJjCqAIrazhBZBgV0DM65?si=d3d1ffdd5f844ea1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Rare Form</strong><br/>
              (prod. griesgrammar)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2Enla1jkgqw4BzcGej7vRK?si=0b54f52e83dc439c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Mission Impossible</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4rJ6jGykNtPScdpnHeg5ya?si=7d932c631fe94b94" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Since You Left (feat. Nyquil)</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7yqBXF8pzFY8zkJqI9QM7b?si=97b986f49c444f6b" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-lCzzd3LD8y3xT9Yz-fAk45Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>PJ</strong><br/>
              (prod. Eddie B)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1aXuN6pb7sIwt611TudKCe?si=78c86e7d83ff466e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-HooCZoXylBb33wNv-PB4mjA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I'm Not Punk.</strong><br/>
              (prod. Jang)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6Jp3mV1z1DSrWfjbZK2LJP?si=7d60525c86d84012" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Szt88q4Fuc2vXg6r-FBABWQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Feels Like Im Dying</strong><br/>
              (prod. abelskittles)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7i1XzmOWFVHP79olcW3YGi?si=e15960d0adb4416a" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Szt88q4Fuc2vXg6r-FBABWQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Online Friends</strong><br/>
              (prod. abelskittles)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6KGvwWqgnRz7MxIEhDSKFe?si=50ed8cb9b0284ed3" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-DUdXEyucgfsIXESx-5lM0Mw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Hey Alexa (feat. Nyquil)</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/kawaiiry-wasteland" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-pXlzlEN7RjbzC1sU-Fylatw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Wasteland</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4OYLWFaPBDoIdzTXvuqrkp?si=05c7e59a858140ee" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-n3NnSUaiJijbIAPk-0xDgzg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Phone Off (feat. Killtheheretik)</strong><br/>
              (prod. Meauxminy)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4YfItgXOfFih4CqoDwHChC?si=1e6e83f7fb5b4a0e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-c4lUNRQ9kq2VZ9Lp-pwya1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I Have 2 Moods</strong><br/>
              (prod. Baredex)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4t1Pb8lrHLngy728FFHvQr?si=89f87041aab54726" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-c4lUNRQ9kq2VZ9Lp-pwya1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Guess It's Like That</strong><br/>
              (prod. Baredex)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2NUKAX6AjQX7AC1b22ndVi?si=2d619403021c4fb7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-c4lUNRQ9kq2VZ9Lp-pwya1Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>7 Deadly Flows Freestyle</strong><br/>
              (prod. Baredex)
            </p>
          </a>
        </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7LklZ2szUrT6DlRFeTOSim?si=b09aafc1a6884d34" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-SRXfoa59wlkrXuLc-dAt4gA-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry & Lil Dujour - Straight 100</strong><br/>
                (prod. MDST)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/5lU2ywtwxc83uIAB90BUKz?si=233ced73afeb47c8" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-SRXfoa59wlkrXuLc-dAt4gA-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry & Lil Dujour - State 2 State</strong><br/>
                (prod. Charlie Shuffler)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/4LorRg9eTGXtOps9R10Av6?si=2cdee346287041c7" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-SRXfoa59wlkrXuLc-dAt4gA-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry & Lil Dujour - Roundabout</strong><br/>
                (prod. rossgossage)
              </p>
            </a>
          </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7c3M9WV1fu8EWZvQElZ7nt?si=3b17d7aeecc74885" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-1XdkqyuiQq31lSzw-1cP5xQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Summer Love (Falling Apart)</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4BE0de8CDBPAIQVwserOqa?si=084e9929958442b6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JTFoDyKFC82Efh1H-Oh6Aig-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Fade 2 Black</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0RNfZtR732Bpwk3LsUING6?si=28f1912f1e0449b3" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JobXAW6ySm88HPEl-yVqtkg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Lethal Injection</strong><br/>
              (prod. Vin Ace)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4ZDzGIZIrftuqB6TSFKK9O?si=d340dffce6a84e45" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-Kg2LMlXQg8JwCMxe-xp42Qw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Miss This</strong><br/>
              (prod. Vin Ace)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6kDSdMjFjtfInLd0ih3Ijc?si=37a3272389fa4ce1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-DyIzktaoz1LKVb2r-y9pwMg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Unforgiven</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/78t6EhAVHTwdNs8brPTbuy?si=b3761a7bc5834707" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JeLnYTfsB5fpiULe-BlyBAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Roses and Tulips</strong><br/>
              (prod. caps ctrl)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5iVxs6h1pwFKKvUZtQGfh8?si=8a6ae6ad554a4966" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JeLnYTfsB5fpiULe-BlyBAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Ball Today</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4pe5r1w7yjnxQUVw0msCAp?si=a7c6aa6479384fdd" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JeLnYTfsB5fpiULe-BlyBAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>No Pics (feat. LOVe.GOUST)</strong><br/>
              (prod. Rezza)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4i4ocwYh7iJIfldw5Mga5u?si=6d5d05c9bf714bb0" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JeLnYTfsB5fpiULe-BlyBAQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Wish U Well</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1AoyhRi9gpjkGGh14fL3sS?si=81c49916e9824251" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-G4PLdfHrtZ5eO43Y-mJI0KQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Spine (feat. OGS)</strong><br/>
              (prod. Rezza)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7hYOiBrRAC4hNkRY28CwhM?si=658ac544036548a7" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-G4PLdfHrtZ5eO43Y-mJI0KQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Where Ya Been?</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7M1LtR3ytemJkPuqKA3xZU?si=bbcf06b185274d69" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-G4PLdfHrtZ5eO43Y-mJI0KQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I'm Wrong</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6jolT8COPSIeWA8SGashOF?si=b2e34a302a7c41cd" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-G4PLdfHrtZ5eO43Y-mJI0KQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Counterfeits</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0VGL1Yp1aUy8zyMwtTBpIQ?si=ec76e5d3e7d34255" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-vnIuTJmw8DJoGJ0f-OgLybQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Not Broken</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/16rojT45LRU5TLLOVHlFnR?si=f4262f18bdf2435e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-W0ru9mnXyB4siyJz-yGjW4Q-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Vampire Shawty</strong><br/>
              (prod. Eddie B)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0bpoKggUg9MG9fCbVfF3Jx?si=1e5f2caf12b44ddf" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-ZUVxZpT2WUL1uUTY-T0JrMQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Tell Me</strong><br/>
              (prod. Curtains)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6cqAGXnNvNApIRZpFA6WtW?si=77458419b9d440f1" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-ZUVxZpT2WUL1uUTY-T0JrMQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>D.L.M.F.C</strong><br/>
              (prod. Curtains)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5H8k7TvDiAXFQxt07OBee8?si=a64a19b2de854e46" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-ZUVxZpT2WUL1uUTY-T0JrMQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Why</strong><br/>
              (prod. Curtains)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/43IUGodArkkofdcUIO1YkN?si=0baacfacc0ab4a26" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-4O7F1xmti53F7pFf-14wdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Too Deep</strong><br/>
              (prod. Valious + Ross Gossage)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3URu8rJF81NqvZVn71DOc5?si=efd56335c94a4254" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-4O7F1xmti53F7pFf-14wdCg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Sharks</strong><br/>
              (prod. MDST)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0L2XDwH0qblogeYb5samn4?si=e8aa8ada06654af2" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-CseIW0uhch23VMdR-gYOhLg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Voices (feat. Remy3D)</strong><br/>
              (prod. MDST)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/22bI3T2wcrpSaeaY7QyUAV?si=bfac7b297f6748e0" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F9hNib4IAF0IwrfA-LTreyA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Break My Heart Again</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3DBrQfARUigQ7QsykVToQj?si=6962a7e515124715" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F9hNib4IAF0IwrfA-LTreyA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>No More</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1MdhNmWZ09JDFHFLqnfw4f?si=fa2c1649b31c43b4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F9hNib4IAF0IwrfA-LTreyA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Close Call</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/17HKijvyHrbVdeDpXymXjb?si=2ab4b10452ce47a2" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-F9hNib4IAF0IwrfA-LTreyA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Gucci Rehab (feat. OGS)</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5jnoj36nxCLcCYDoXFRj72?si=e0bbde1fcda142b3" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-0YhVJ3zwY6s7006L-1iKILA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Somebody Save Me (H-Town)</strong><br/>
              (prod. lupinekwakeup)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1iuALSErZE0Nm6HasuWWO6?si=944d2d3abfb04d1c" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-ufuleIqvYMW1wQdn-MEjOZw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Girls Like You</strong><br/>
              (prod. Eddie B)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4hs0rbKF12TXTgP2cBrUXE?si=6d8428dd434f42cd" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-97rj9GPmqRUF0kdA-0VzT6g-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Lost Her (feat. Remy3D)</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3ZuOO9iz3KO0Z221sDmK7B?si=75d920a54cc04c66" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-UgW08tsV2bIW3Cy1-csymoQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry, Dan - It's Over</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4K258rLmG3NUQoXWY85vxP?si=a62c2396206549f3" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-wmm8TwQ4LLwrcVaJ-j9Xgzw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Crash It</strong><br/>
              (prod. lil weest)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/34DF7db7fKqQupYDI7v7gH?si=21aee9df3d1f408d" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-JrAczQ7F5y3pvdF1-owQaWQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Got That Aim</strong><br/>
              (prod. Eddie B)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4XQspe9zl8uozVTFpyzFgi?si=17991e910613454e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-gRB2JiebvSSlWniY-owwORA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I Miss U</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/7I8ilASjQNlLUtLrb46Md8?si=1bf611228daa4240" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-7Oy5wuUeH33K7k8H-HzA6eQ-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry, Dan - my way</strong><br/>
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/2TFbb07WupnPtyDaPZaXpD?si=911b965029be404e" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-SAqh0h5qdxgxhD0o-l9xtVA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>By Any Means (feat. Slim.B)</strong><br/>
              (prod. Wellfed)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/08q1tjxF7RKHeUUhNAmH8A?si=426780fbb70547e9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-iefZDvaQQ3gxzzTp-ujrfpw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Killing Innocent</strong><br/>
              (prod. TY Andrew)
            </p>
          </a>
        </div>

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/3xYCxCI1OcNQZ0GpdC3tlN?si=1d476be7f9c24574" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-6RtdBlMA5SVPQ3Tz-3eV1yw-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Red Corvette</strong><br/>
              (prod. getzh)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4MimA3kFQiAqAR9Hwlp6Fb?si=3dc7aa70f38944d6" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-YhIXTAVp7F7zFKOH-Per6Ug-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Demons</strong><br/>
              (prod. taurs)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1h5TVcPgq2Lnd7v9BAUtEK?si=4378e7c8eeab43f9" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-ayyfwx1fejBuZ2aY-G6yIMg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Puerto Rico (feat. OGS)</strong><br/>
              (prod. kylejunior)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/0AsFKjON1lWcLfWSO8E6Wl?si=44655f4c1cd642e0" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-rzCVFgmHPA9yWTAT-jBcDNA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>I'm On</strong><br/>
              (prod. Charlie Shuffler)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/1N5P23Sk9thMA4CekfpyZo?si=634f183669d94262" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-hI5gzMPuB1sIYlEr-F9ff4A-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Tear Drops (feat. Remy3D)</strong><br/>
              (prod. youngfyebeatz)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/61YHULUgsl3gK2xpCg2UF6?si=dc133627e54a4135" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-epviRLwovij20K3c-pLHSIg-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Last Dance</strong><br/>
              (prod. foreignboi)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/5nhGGJR3u2jNmtNn7zryyt?si=75780c10dbde44de" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-GpGycBdzeB1sOri5-XBXTpA-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Overdid It</strong><br/>
              (prod. aeekay)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/4SVWDcD3QVR2lazoV3ilAx?si=f864bdcc2e2b4ea4" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-000676273576-x0qy5d-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Past Now</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6ZNDFPjvmSaV4yavSaTVvw?si=183b153d47ab42ac" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-000672443362-snlp08-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Zoom</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://soundcloud.com/kawaiiry/kawaiiry-x-dan-worst-day" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-000660018088-gnmxpv-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Kawaii Ry, Dan - Worst Day</strong><br/>
              (prod. Dan)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/6dgklY71clFB71v1T5sHJ9?si=d8f495b95cb04815" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-000647934679-ue0ary-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>Shining</strong><br/>
              (feat. Rustee)
            </p>
          </a>
        </div> 

        <div class="gallery_item">
          <p class="gallery-tag">Single</p>
          <a href="https://open.spotify.com/track/40QM7PQdHjqWyXVXhxZWCM?si=8ecf48670de440a3" target="_blank" rel="noopener noreferrer">    
            <img src="https://i1.sndcdn.com/artworks-000627871354-x31p7a-t500x500.jpg" width="100%" alt=""/><br/>
            <p class="gallery-text">
              <strong>My Space</strong><br/>
              (prod. Yunny Goldz & Rustee)
            </p>
          </a>
        </div>   

      </div>
    </>
  );
};
export default KawaiiSinglesStatic;
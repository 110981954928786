const ArchiveStreamVodsStatic = () => {
    return (
      <>
        <div class="music-video"> 

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/-73dISzZ5rs" title=" " allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>August 13, 2023</strong> - ANJU Online! + Kawaii Ry [League of Legends]
          </p>  

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/QBUUTihFof4" title=" " allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>August 12, 2023</strong> - ANJU Online! + Kawaii Ry [League of Legends]
          </p>                                                                                                                           
        
        </div>
      </>
    );
  };
  export default ArchiveStreamVodsStatic;